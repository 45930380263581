import Hero from "./Components/Hero";
import FeaturesV1 from "./Components/FeaturesV1";
import FeaturesV2 from "./Components/FeaturesV2";
import Faq from "./Components/Faq";

const Features = () => {

    return(
        <>
            {/*<DarkNavBar page={''}/>*/}
            <Hero />
            <FeaturesV1 />
            <FeaturesV2 />
            <Faq />
        </>
    )

}

export default Features
