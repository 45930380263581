import Hero from "./Components/Hero";
import mainLogo from "../../assets/images/bitemate/DEFAULT_LOGO_LIGHT.svg"
import Footer from "./Components/Footer";
import Faq from "./Components/Faq";
import DarkNavBar from "../../components/DarkNavBar";

const PricingPage = () => {

    return(
        <div>
            {/*<DarkNavBar />*/}
            <Hero />
            <div style={{ height: '200px'}}/>
            {/*<section className="client-section section-spacing">*/}
            {/*    <div data-w-id="1c19d208-eaee-bf81-a1c7-76572b0a81fd"*/}
            {/*         style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}}*/}
            {/*         className="w-layout-blockcontainer container-small w-container">*/}
            {/*        <div className="section-title text-center">*/}
            {/*            <h3 className="client-title">Our clients</h3>*/}
            {/*        </div>*/}
            {/*        <div className="client-image-one"><img*/}
            {/*            src="./Pricing 3 _ SasPro - Webflow Ecommerce website template_files/663b25cb0687acfa75ede3af_client-18.svg"*/}
            {/*            loading="eager" alt="Client Image" className="client-image"/><img*/}
            {/*            src="./Pricing 3 _ SasPro - Webflow Ecommerce website template_files/663b25caedb2d7b57e650649_client-17.svg"*/}
            {/*            loading="eager" alt="Client Image" className="client-image"/><img*/}
            {/*            src="./Pricing 3 _ SasPro - Webflow Ecommerce website template_files/663b25ca1c164df3680afebb_client-13.svg"*/}
            {/*            loading="eager" alt="Client Image" className="client-image"/><img*/}
            {/*            src="./Pricing 3 _ SasPro - Webflow Ecommerce website template_files/663b25ca3e867354e2f1064f_client-16.svg"*/}
            {/*            loading="eager" alt="Client Image" className="client-image"/><img*/}
            {/*            src="./Pricing 3 _ SasPro - Webflow Ecommerce website template_files/663b25cadef411e0461c8ed5_client-19.svg"*/}
            {/*            loading="eager" alt="Client Image" className="client-image"/></div>*/}
            {/*        <div className="client-image-two"><img*/}
            {/*            src="./Pricing 3 _ SasPro - Webflow Ecommerce website template_files/663b25cab92b5d7cbd2c7a5b_client-15.svg"*/}
            {/*            loading="eager" alt="Client Image" className="client-image"/><img*/}
            {/*            src="./Pricing 3 _ SasPro - Webflow Ecommerce website template_files/663b25c9e3e50e7f397f937a_client-12.svg"*/}
            {/*            loading="eager" alt="Client Image" className="client-image"/><img*/}
            {/*            src="./Pricing 3 _ SasPro - Webflow Ecommerce website template_files/663b25c9451a026d81334bbb_client-14.svg"*/}
            {/*            loading="eager" alt="Client Image" className="client-image"/><img*/}
            {/*            src="./Pricing 3 _ SasPro - Webflow Ecommerce website template_files/663b25c922c43a6fc3e78228_client-11.svg"*/}
            {/*            loading="eager" alt="Client Image" className="client-image"/></div>*/}
            {/*    </div>*/}
            {/*</section>*/}
            <Faq />
        </div>
    )

}

export default PricingPage;
