import check01 from "../../../assets/images/defaults/663b162c7b82fc7fa08c716b_check-circle-01.svg"
import check02 from "../../../assets/images/defaults/663b162b74e28bff2b664f52_check-circle-02.svg"
import decoration01 from "../../../assets/images/defaults/6641b344baa1ededaa5b27dd_decoration-01-1.svg";
import decoration03 from "../../../assets/images/defaults/6641b35fbdafbc2bc9b06b24_decoration-03-1.svg";

const Hero = () => {

    return (
        <section className="split-hero-pricing-section">
            <div className="pricing-items">
                <img src={decoration01} loading="eager" alt="Decoration" className="feature-decoration-one"/>
                <img src={decoration03} loading="eager" alt="Decoration" className="feature-decoration-two"/>
                <div data-w-id="47b4fde1-384f-0917-3cc5-a547fe073ed6"
                     style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}}
                     className="w-layout-blockcontainer container w-container">
                    <div className="pricing-section-title">
                        <h1 className="text-white">Transparent pricing for every business size</h1>
                    </div>
                    <div>
                        <div className="toggle-wrapper">
                            <div className="toggle-text">Monthly</div>
                            <div data-w-id="47b4fde1-384f-0917-3cc5-a547fe073ede" className="pricing-toggle"
                                 style={{backgroundColor: 'rgb(121, 121, 121)'}}>
                                <div
                                    style={{transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}}
                                    className="toggle-circle"></div>
                            </div>
                            <div className="toggle-text">Yearly<span></span></div>
                        </div>
                        <div style={{ textAlign: 'left'}} className="w-layout-grid grid-pricing">
                            <div id="w-node-_47b4fde1-384f-0917-3cc5-a547fe073ee5-f88a5567"
                                 className="pricing-item bg-secondary-3">
                                <h2 className="pricing-title">Basic Plan</h2>
                                <p className="pricing-description">Get started confidently with our Beginner
                                    Plan.</p>
                                <div className="pricing-divider"></div>
                                <div>
                                    <div style={{opacity: 1, display: 'grid'}} className="pricing-monthly">
                                        <div className="pricing-simple-title">
                                            <h1 className="pricing-price">$20</h1>
                                            <div>/</div>
                                            <div>month</div>
                                        </div>
                                    </div>
                                    <div style={{opacity: 0, display: 'none'}} className="pricing-annual">
                                        <div className="pricing-simple-title">
                                            <h1 className="pricing-price">$10</h1>
                                            <div>/</div>
                                            <div>month</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pricing-list-wrap">
                                    <div className="pricing-list">
                                        <img
                                            src={check01}
                                            loading="eager" alt="Check Icon" className="pricing-icon"/>
                                        <div>Limited projects/tasks</div>
                                    </div>
                                    <div className="pricing-list">
                                        <img
                                            src={check01}
                                            loading="eager" alt="Check Icon" className="pricing-icon"/>
                                        <div>Community forum access</div>
                                    </div>
                                    <div className="pricing-list one">
                                        <img
                                            src={check01}
                                            loading="eager" alt="Check Icon" className="pricing-icon"/>
                                        <div>Basic reporting and analytics</div>
                                    </div>
                                    <div className="pricing-list two">
                                        <img
                                            src={check01}
                                            loading="eager" alt="Check Icon" className="pricing-icon"/>
                                        <div>Basic features to get started</div>
                                    </div>
                                    <div className="pricing-list two">
                                        <img
                                            src={check01}
                                            loading="eager" alt="Check Icon" className="pricing-icon"/>
                                        <div>Email support during business hours</div>
                                    </div>
                                </div>
                                <a href="https://saspro-wbs.webflow.io/help-pages/contact"
                                   className="button-black button-full w-button">Get started</a>
                            </div>
                            <div id="w-node-_47b4fde1-384f-0917-3cc5-a547fe073f13-f88a5567"
                                 className="pricing-item bg-primary-1">
                                <h2 className="pricing-title text-white">Standard Plan</h2>
                                <p className="pricing-description text-gray-3">Comprehensive support with our
                                    Extended Plan.</p>
                                <div className="pricing-divider-white"></div>
                                <div>
                                    <div style={{opacity: 1, display: 'grid'}} className="pricing-monthly">
                                        <div className="pricing-simple-title text-white">
                                            <h1 className="pricing-price text-white">$40</h1>
                                            <div className="text-white">/</div>
                                            <div className="text-white">month</div>
                                        </div>
                                    </div>
                                    <div style={{opacity: 0, display: 'none'}} className="pricing-annual">
                                        <div className="pricing-simple-title text-white">
                                            <h1 className="pricing-price text-white">$25</h1>
                                            <div className="text-white">/</div>
                                            <div className="text-white">month</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pricing-list-wrap">
                                    <div className="pricing-list">
                                        <img
                                            src={check02}
                                            loading="eager" alt="Check Icon" className="pricing-icon"/>
                                        <div className="text-white">Increased project/task limits</div>
                                    </div>
                                    <div className="pricing-list">
                                        <img
                                            src={check02}
                                            loading="eager" alt="Check Icon" className="pricing-icon"/>
                                        <div className="text-white">Customization options</div>
                                    </div>
                                    <div className="pricing-list">
                                        <img
                                            src={check02}
                                            loading="eager" alt="Check Icon" className="pricing-icon"/>
                                        <div className="text-white">Integration with premium tools</div>
                                    </div>
                                    <div className="pricing-list">
                                        <img
                                            src={check02}
                                            loading="eager" alt="Check Icon" className="pricing-icon"/>
                                        <div className="text-white">Regular performance reviews</div>
                                    </div>
                                    <div className="pricing-list one">
                                        <img
                                            src={check02}
                                            loading="eager" alt="Check Icon" className="pricing-icon"/>
                                        <div className="text-white">Access to exclusive resources</div>
                                    </div>
                                </div>
                                <a href="https://saspro-wbs.webflow.io/help-pages/contact"
                                   className="button-secondary-3 button-full w-button">Get started</a>
                            </div>
                            <div id="w-node-_47b4fde1-384f-0917-3cc5-a547fe073f41-f88a5567"
                                 className="pricing-item bg-secondary-3">
                                <h2 className="pricing-title">Team Plan</h2>
                                <p className="pricing-description">Optimize teamwork with our streamlined Team
                                    Plan.</p>
                                <div className="pricing-divider"></div>
                                <div>
                                    <h2 className="pricing-titles">Let’s talk!</h2>
                                </div>
                                <div className="pricing-list-wrap">
                                    <div className="pricing-list">
                                        <img
                                            src={check01}
                                            loading="eager" alt="Check Icon" className="pricing-icon"/>
                                        <div>Unlimited users monthly</div>
                                    </div>
                                    <div className="pricing-list">
                                        <img
                                            src={check01}
                                            loading="eager" alt="Check Icon" className="pricing-icon"/>
                                        <div>Free 15 host &amp; domain</div>
                                    </div>
                                    <div className="pricing-list">
                                        <img
                                            src={check01}
                                            loading="eager" alt="Check Icon" className="pricing-icon"/>
                                        <div>Up to 05 users monthly</div>
                                    </div>
                                    <div className="pricing-list">
                                        <img
                                            src={check01}
                                            loading="eager" alt="Check Icon" className="pricing-icon"/>
                                        <div>Team performance analytics</div>
                                    </div>
                                    <div className="pricing-list">
                                        <img
                                            src={check01}
                                            loading="eager" alt="Check Icon" className="pricing-icon"/>
                                        <div>Team activity tracking</div>
                                    </div>
                                </div>
                                <a href="https://saspro-wbs.webflow.io/help-pages/contact"
                                   className="button-black button-full w-button">Get started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Hero;
