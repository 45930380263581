import Articles from "./Components/Articles";
import Hero from "./Components/Hero";

const HelpCenterTopicPage = () => {

    return (
        <>
            <Hero />
            <Articles />
        </>
    )

}

export default HelpCenterTopicPage
