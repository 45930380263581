import starImage from "../../../assets/images/defaults/6638b0e7ceec979a4b9f1ac8_star-image.svg";
import heroImage from "../../../assets/images/defaults/664d74bc4ee9317fc52938eb_hero-image.jpg";
import dashboard01 from "../../../assets/images/defaults/6638b77fcf66901576ff4a4d_dashboard-01.svg";
import decoration04 from "../../../assets/images/defaults/6638ba24723c737352aab4fa_decoration-04.svg";
import decoration05 from "../../../assets/images/defaults/6638ba25c5054601ba081432_decoration-05.svg";
import decoration01 from "../../../assets/images/defaults/6641b344baa1ededaa5b27dd_decoration-01-1.svg";
import decoration03 from "../../../assets/images/defaults/6641b35fbdafbc2bc9b06b24_decoration-03-1.svg";


const Hero = () => {

    return (
        <section className="hero-section section-spacing-top">
            <div data-w-id="16912616-de3e-0f92-bd8d-83ce6dde3c8e"
                 style={{
                     opacity: 1,
                     textAlign: 'left',
                     transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                     transformStyle: 'preserve-3d'
                 }}
                 className="w-layout-blockcontainer container w-container">
                <div className="hero-top-title-wrap">
                    <div className="hero-top-title">
                        <div>Powerful platform</div>
                        <img
                            src={starImage}
                            loading="eager" alt="Star Image"/>
                        <div>4.9</div>
                    </div>
                </div>
                <h1 className="hero-title">
                    Revolutionize
                    <span className="hero-title-light"> your </span>
                    restaurant
                    <span className="hero-title-light"> with our </span>
                    customizable
                    <span className="hero-title-light"> app</span>
                </h1>
                <p className="hero-description">
                    Take control of your restaurant’s digital
                    presence with our plug-and-play, fully
                    customizable mobile app. Whether you’re
                    already using a POS, or just need a
                    standalone solution, our SaaS seamlessly
                    integrates to provide a smooth experience
                    for your customers and staff.
                </p>
                <a href="https://saspro-wbs.webflow.io/product-pages/pricing-1"
                   className="button-primary-1 w-button">Start your free trial</a>
            </div>
            <div className="w-layout-grid grid-hero">
                <div id="w-node-_16912616-de3e-0f92-bd8d-83ce6dde3ca2-1715374c"
                     data-w-id="16912616-de3e-0f92-bd8d-83ce6dde3ca2"
                     style={{
                         opacity: 1,
                         transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                         transformStyle: 'preserve-3d'
                     }}
                     className="hero-image-wrap"><img
                    src={heroImage}
                    loading="eager"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 73vw, (max-width: 991px) 79vw, (max-width: 1279px) 47vw, (max-width: 1919px) 53vw, 736.625px"
                    srcSet="https://cdn.prod.website-files.com/6638734fc8194e46def84df4/664d74bc4ee9317fc52938eb_hero-image-p-500.jpg 500w, https://cdn.prod.website-files.com/6638734fc8194e46def84df4/664d74bc4ee9317fc52938eb_hero-image-p-800.jpg 800w, https://cdn.prod.website-files.com/6638734fc8194e46def84df4/664d74bc4ee9317fc52938eb_hero-image-p-1080.jpg 1080w, https://cdn.prod.website-files.com/6638734fc8194e46def84df4/664d74bc4ee9317fc52938eb_hero-image.jpg 1300w"
                    alt="Hero Image" className="hero-image"/><img
                    src={dashboard01}
                    loading="eager" alt="Dashboard" className="dashboard-one"/></div>
                <div id="w-node-_16912616-de3e-0f92-bd8d-83ce6dde3ca5-1715374c"
                     data-w-id="16912616-de3e-0f92-bd8d-83ce6dde3ca5"
                     style={{
                         opacity: 1,
                         transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                         transformStyle: 'preserve-3d'
                     }}
                     className="hero-content-box">
                    <div>
                        <h2 className="hero-titles">4.5X</h2>
                        <div className="text-white">Worldwide sales increase</div>
                    </div>
                    <div className="content-right">
                        <p className="hero-content">Expand your market reach, tap into new territories, and drive
                            revenue to unprecedented heights.</p>
                        <a href="https://saspro-wbs.webflow.io/product-pages/feature-1"
                           className="button-black w-button">Explore all features</a>
                    </div>
                    <img
                        src={decoration04}
                        loading="eager" alt="Decoration" className="decoration-four"/><img
                    src={decoration05}
                    loading="eager" alt="Decoration" className="decoration-five"/>
                </div>
            </div>
            <img
                src={decoration01}
                loading="eager" alt="Decoration" className="decoration-one"/><img
            src={decoration03}
            loading="eager" alt="Decoration" className="decoration-three"/>
        </section>
    )

}

export default Hero;
