import blob01 from "../../../assets/images/defaults/6639f613745449ab9883549b_blog-01.jpg"
import buttonIcon from "../../../assets/images/defaults/6642ed3519684198096e8f41_button-icon.svg"
import decoration05 from "../../../assets/images/defaults/6638ba25c5054601ba081432_decoration-05.svg"
import decoration04 from "../../../assets/images/defaults/6638ba24723c737352aab4fa_decoration-04.svg"

const Resources = () => {

    return (
        <section className="blog-section section-spacing-top">
            <div className="w-layout-blockcontainer container w-container">
                <div className="section-title text-center">
                    <h2 data-w-id="b4695118-e9d9-1c61-0015-bb63d4c07d89"
                        style={{
                            opacity: 1,
                            transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d'
                        }}>Explore
                        helpful resources</h2>
                </div>
                <div className="simple-blog-wrap">
                    <div data-w-id="b4695118-e9d9-1c61-0015-bb63d4c07d8c"
                         style={{
                             opacity: 1,
                             transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                             transformStyle: 'preserve-3d'
                         }}
                         className="w-dyn-list">
                        <div style={{textAlign: 'left'}} role="list" className="w-dyn-items">
                            <div role="listitem" className="w-dyn-item">
                                <a data-w-id="b4695118-e9d9-1c61-0015-bb63d4c07d8f"
                                   href="https://saspro-wbs.webflow.io/blog/tips-and-tricks-for-maximizing-your-crm-system"
                                   className="simple-blog-item-one w-inline-block">
                                    <div className="simple-blog-image-wrap"><img
                                        src={blob01}
                                        loading="eager"
                                        style={{
                                            transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                                            transformStyle: 'preserve-3d'
                                        }}
                                        alt=""
                                        sizes="(max-width: 479px) 94vw, (max-width: 767px) 95vw, (max-width: 991px) 46vw, (max-width: 1919px) 22vw, 327.5px"
                                        srcSet="https://cdn.prod.website-files.com/6638734fc8194e46def84e16/6639f613745449ab9883549b_blog-01-p-500.jpg 500w, https://cdn.prod.website-files.com/6638734fc8194e46def84e16/6639f613745449ab9883549b_blog-01.jpg 800w"
                                        className="simple-blog-image"/></div>
                                    <h3 className="heading-h6">Tips and tricks for maximizing your mobile app</h3>
                                    <div className="blog-category">Design</div>
                                    <div className="button-icon">
                                        <div>Read more</div>
                                        <img
                                            src={buttonIcon}
                                            loading="eager" alt="Button Icon" className="button-icons"/>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div data-w-id="b4695118-e9d9-1c61-0015-bb63d4c07d9b"
                         style={{
                             opacity: 1,
                             textAlign: 'left',
                             transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                             transformStyle: "preserve-3d"
                         }}
                         className="w-dyn-list">
                        <div role="list" className="w-dyn-items">
                            <div role="listitem" className="w-dyn-item">
                                <a href="https://saspro-wbs.webflow.io/blog/exclusive-insights-and-behind-the-scenes-stories"
                                   className="simple-blog-item-two bg-secondary-2 w-inline-block">
                                    <div className="blog-title-wrap">
                                        <div className="simple-blog-category">Development</div>
                                        <h4 className="simple-blog-title">Need to know: App customizations and configuration</h4>
                                    </div>
                                    <div className="button-icon">
                                        <div>Read more</div>
                                        <img
                                            src={buttonIcon}
                                            loading="eager" alt="Button Icon" className="button-icons"/>
                                    </div>
                                    <img
                                        src={decoration05}
                                        loading="lazy" alt="Decoration" className="blog-decoration-one"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div id="w-node-b4695118-e9d9-1c61-0015-bb63d4c07daa-1715374c"
                         data-w-id="b4695118-e9d9-1c61-0015-bb63d4c07daa"
                         style={{
                             opacity: 1,
                             textAlign: 'left',
                             transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                             transformStyle: 'preserve-3d'
                         }}
                         className="w-dyn-list">
                        <div role="list" className="w-dyn-items">
                            <div role="listitem" className="w-dyn-item">
                                <a data-w-id="b4695118-e9d9-1c61-0015-bb63d4c07dad"
                                   href="https://saspro-wbs.webflow.io/blog/discovering-the-art-of-relaxation"
                                   className="simple-blog-item-one w-inline-block">
                                    <div className="simple-blog-image-wrap"><img
                                        src="../../../assets/images/defaults/6639f65314829364cd4b2198_blog-03.jpg"
                                        loading="eager"
                                        style={{
                                            transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                                            transformStyle: 'preserve-3d'
                                        }}
                                        alt=""
                                        sizes="(max-width: 479px) 94vw, (max-width: 767px) 95vw, (max-width: 991px) 46vw, (max-width: 1919px) 22vw, 327.5px"
                                        srcSet="https://cdn.prod.website-files.com/6638734fc8194e46def84e16/6639f65314829364cd4b2198_blog-03-p-500.jpg 500w, https://cdn.prod.website-files.com/6638734fc8194e46def84e16/6639f65314829364cd4b2198_blog-03.jpg 800w"
                                        className="simple-blog-image"/></div>
                                    <h5 className="heading-h6">Launching your new community</h5>
                                    <div className="blog-category">Products</div>
                                    <div className="button-icon">
                                        <div>Read more</div>
                                        <img
                                            src={buttonIcon}
                                            loading="eager" alt="Button Icon" className="button-icons"/>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div id="w-node-b4695118-e9d9-1c61-0015-bb63d4c07db9-1715374c"
                         data-w-id="b4695118-e9d9-1c61-0015-bb63d4c07db9"
                         style={{
                             opacity: 1,
                             textAlign: 'left',
                             transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                             transformStyle: 'preserve-3d'
                         }}
                         className="w-dyn-list">
                        <div role="list" className="w-dyn-items">
                            <div role="listitem" className="w-dyn-item">
                                <a href="https://saspro-wbs.webflow.io/blog/exploring-the-best-activities-and-excursions"
                                   className="simple-blog-item-two bg-primary-3 w-inline-block">
                                    <div className="blog-title-wrap">
                                        <div className="simple-blog-category">News</div>
                                        <h6 className="simple-blog-title">Exploring all that BiteMate has to offer</h6>
                                    </div>
                                    <div className="button-icon">
                                        <div>Read more</div>
                                        <img
                                            src={buttonIcon}
                                            loading="eager" alt="Button Icon" className="button-icons"/>
                                    </div>
                                    <img
                                        src={decoration04}
                                        loading="lazy" alt="Decoration" className="blog-decoration-two"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Resources;
