import decoration01 from "../../../assets/images/defaults/6641b344baa1ededaa5b27dd_decoration-01-1.svg";
import decoration03 from "../../../assets/images/defaults/6641b35fbdafbc2bc9b06b24_decoration-03-1.svg";

const Hero = () => {

    return (
        <section className="feature-detail-section">
            <div className="w-layout-blockcontainer container w-container">
                <div data-w-id="db355e07-aedc-7486-d167-3a1ac1dc36bd"
                     style={{
                         opacity: 1,
                         transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                         transformStyle: 'preserve-3d'
                     }}
                     className="feature-detail-title-wrap">
                    <h1 className="feature-detail-title">Advanced analytics dashboard</h1>
                    <p className="feature-detail-description">Gain valuable insights into your business operations
                        with our intuitive analytics dashboard. Gain valuable insights into your digital campaigns
                        with our comprehensive data analytics and reporting feature. Explore our comprehensive
                        segmentation capabilities, allowing you to analyze specific subsets.</p>
                </div>
            </div>
            <img src={decoration01} loading="eager" alt="Decoration" className="feature-decoration-one"/>
            <img src={decoration03} loading="eager" alt="Decoration" className="feature-decoration-two"/>
        </section>
    )

}

export default Hero;
