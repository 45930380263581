import mainLogo from "../assets/images/bitemate/DEFAULT_LOGO_LIGHT.svg";
import { useLocation } from 'react-router-dom'

const DarkNavBar = ({page}: {page: String}) => {

    const { pathname } = useLocation();

    return(
        <>
            <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease"
                 data-easing2="ease" role="banner" className="navbar-light w-nav">
                <div className="container w-container">
                    <div className="w-layout-grid grid-nav">
                        <a href="https://www.bitemate.co/"
                           id="w-node-_87ed2971-c204-f0ef-8cce-6578f672691e-f672691b" aria-current="page"
                           className="brand w-nav-brand w--current">
                            <img src={mainLogo} loading="eager" alt="Logo" style={{ maxHeight: '30px' }}
                                 className="logo"/>
                        </a>
                        <nav role="navigation" id="w-node-_87ed2971-c204-f0ef-8cce-6578f6726920-f672691b"
                             className="nav-menu w-nav-menu">
                            <a href="/" aria-current="page"
                               className={`nav-link-light w-nav-link ${pathname === '/' ? 'w--current' : ''}`}
                               style={{maxWidth: '1460px'}}>Home</a>
                            <a href="/features"
                               className={`nav-link-light w-nav-link ${pathname === '/features' ? 'w--current' : ''}`}
                               style={{maxWidth: '1460px'}}>Features</a>
                            <a href="/pricing"
                               className={`nav-link-light w-nav-link ${pathname === '/pricing' ? 'w--current' : ''}`}
                               style={{maxWidth: '1460px'}}>Pricing</a>
                            <a href="/demo"
                               className={`nav-link-light w-nav-link ${pathname === '/request-demo' ? 'w--current' : ''}`}
                               style={{maxWidth: '1460px'}}>Request Demo</a>
                            <a href="/help-center"
                               className={`nav-link-light w-nav-link ${pathname?.includes('help-center') ? 'w--current' : ''}`}
                               style={{maxWidth: '1460px'}}>Help Center</a>
                        </nav>
                        <div id="w-node-_87ed2971-c204-f0ef-8cce-6578f6726975-f672691b" className="right-nav">
                            <a href="https://app.bitemate.co/auth/sign-in"
                               className="button-link-white mobile-hide">Sign in</a><a
                            href="https://app.bitemate.co/auth/sign-up"
                            className="button-white mobile-hide w-button">Get started</a>
                            <div className="menu-button w-nav-button" style={{userSelect: 'text'}}
                                 aria-label="menu" role="button" tabIndex="0" aria-controls="w-nav-overlay-0"
                                 aria-haspopup="menu" aria-expanded="false">
                                <div className="w-icon-nav-menu"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"></div>
            </div>
        </>
    )

}

export default DarkNavBar;
