import ArticleHeading from "./ArticleHeading";

const ArticleContent = () => {

    return(
        <>
            <section className="split-pricing-section">
                <ArticleHeading />
                <div className="w-layout-blockcontainer container-medium w-container">
                    <div data-w-id="8e36b68b-6233-f754-723f-2ec23ab6b47d"
                         style={{ opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}
                         className="help-center-topic-content">
                        <div className="rich-text w-richtext">
                            <p>Encountering issues with your service or device can be frustrating, but often, simple
                                troubleshooting steps can help resolve them. Follow these basic troubleshooting steps to
                                identify and fix common problems.</p>
                            <p>Inspect the power cord for any visible damage such as fraying or exposed wires. Ensure
                                that the power cord is securely plugged into both the device and the power outlet. Try
                                using a different power outlet or power strip to rule out issues with the current
                                outlet.</p>
                            <h5><strong>Your source for expert assistance</strong></h5>
                            <p>Whether you're seeking assistance with technical issues, navigating our platform, or
                                understanding our products and services, we're here to provide the support you need.
                                Explore our articles, tutorials, FAQs, and contact options to get the most out of your
                                experience with us.</p>
                            <p>Welcome to your one-stop destination for reliable assistance and solutions!</p>
                            <ul role="list">
                                <li>Ensure that your device is properly powered on and connected to a power source or
                                    network, depending on the issue.
                                </li>
                                <li>Power off your device, wait a few moments, and then power it back on.</li>
                                <li>Make sure that your device's software and firmware are up to date.</li>
                                <li>If you're experiencing issues with a website or app, try clearing your browser's
                                    cache and cookies.
                                </li>
                                <li>Use these tools to run diagnostic tests and troubleshoot the issue.</li>
                                <li>Provide them with as much detail as possible about the problem you're experiencing
                                    for quicker resolution.
                                </li>
                            </ul>
                            <p>From checking your network settings to performing advanced diagnostics, we'll equip you
                                with the knowledge and tools needed to ensure a seamless experience. Follow along with
                                our step-by-step instructions to get back online and stay connected with ease.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}

export default ArticleContent
