import Footer from "../components/Footer";
import DarkNavBar from "../components/DarkNavBar";
import {Outlet} from "react-router-dom";

const LandingLayout = ({ children }) => {

    return(
        <div>
            <DarkNavBar page={''}/>
            <main>
                <Outlet/> {/* This is where child routes will be rendered */}
            </main>
            <Footer/>
        </div>
    )
}

export default LandingLayout;
