import decoration03 from "../../../../assets/images/defaults/6641b35fbdafbc2bc9b06b24_decoration-03-1.svg";
import vectorImage from "../../../../assets/images/defaults/6641b94df7dbcda617d071b4_Vector-1.svg";

const ArticleHeading = () => {

    return (
        <div className="help-center-categories-wrap">
            <img src={decoration03} loading="eager" alt="Decoration"
                 className="pricing-decoration-three"/><img
            src={vectorImage} loading="eager" alt="Decoration"
            className="pricing-decoration-four"/>
            <div className="w-layout-blockcontainer container-medium w-container">
                <h1 className="help-center-detail-title">Understanding pricing plans</h1>
                <p className="help-center-detail-description">Unravel the intricacies of our pricing options and
                    select the ideal plan tailored to meet your needs.</p>
                <div className="help-center-author-wrap">
                    <div><img
                        src="./Understanding pricing plans_files/663ef2c6a2bf83e21b04ac79_team-image-01.jpg"
                        loading="lazy" alt="" sizes="(max-width: 1279px) 70px, (max-width: 1439px) 5vw, 70px"
                        srcSet="https://cdn.prod.website-files.com/6638734fc8194e46def84e16/663ef2c6a2bf83e21b04ac79_team-image-01-p-500.jpg 500w, https://cdn.prod.website-files.com/6638734fc8194e46def84e16/663ef2c6a2bf83e21b04ac79_team-image-01.jpg 800w"
                        className="help-center-author-image"/></div>
                    <div>
                        <h2 className="text-white heading-h6">Dennis Barrett</h2>
                        <div className="text-gray-3">May 16, 2024</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ArticleHeading;
