import aboutImage06 from "../../../assets/images/defaults/6646d3b1aa5bf9372b3acbf7_about-image-06.jpg"
import aboutImage07 from "../../../assets/images/defaults/6646d3b1c7d20765984ca956_about-image-07.jpg"
import checkCircleIcon from "../../../assets/images/defaults/663b581b63c8c8f612a405e3_check-circle.svg"
import featureIcon06 from "../../../assets/images/defaults/6638cca379c09420ccaeff02_feature-icon-06.svg"
import featureIcon05 from "../../../assets/images/defaults/6638cca3eb75300c2c9df194_feature-icon-05.svg"

const FeaturesV4 = () => {

    return(
        <section className="simple-about-section section-spacing-bottom">
        <div className="w-layout-blockcontainer container w-container">
            <div className="w-layout-grid grid-simple-about-one">
                <div id="w-node-_0a613665-5bbe-5e95-66ab-6ef85fb231d0-03618721" data-w-id="0a613665-5bbe-5e95-66ab-6ef85fb231d0" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="simple-about-content">
                    <h2 className="simple-about-title">Tailor-Made Rewards and Real-Time Personalization</h2>
                    <p>Boost Customer Loyalty with a Fully Customizable Rewards Program and Instantly Update Your App's Theme to Match Your Brand—All in Real Time.</p>
                    <div className="divider"></div>
                    <div className="w-layout-grid grid-split-feature-item">
                        <div id="w-node-_663d58f3-8b80-c63b-307b-1b0357b2116b-03618721" className="split-feature-item">
                            <div className="split-feature-icon-wrap bg-secondary-1">
                                <img src={featureIcon05} loading="eager" alt="Feature Icon" className="split-feature-icon"/>
                            </div>
                            <h3 className="heading-h6">Customizable Rewards Program</h3>
                            <p className="no-margin">Create and manage a rewards program that keeps customers coming back.</p>
                        </div>
                        <div id="w-node-_663d58f3-8b80-c63b-307b-1b0357b21172-03618721" className="split-feature-item">
                            <div className="split-feature-icon-wrap bg-primary-3">
                                <img src={featureIcon06} loading="eager" alt="Feature Icon" className="split-feature-icon"/>
                            </div>
                            <h4 className="heading-h6">Instant Theme Changes</h4>
                            <p className="no-margin">Update the look and feel of your app with real-time theme adjustments.</p>
                        </div>
                    </div>
                </div>
                <div id="w-node-_3e3cff93-d71c-aa10-f046-a821a21ea161-03618721" data-w-id="3e3cff93-d71c-aa10-f046-a821a21ea161" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="simple-about-image-wrap">
                    <img src={aboutImage06} loading="eager" alt="About Image" className="simple-about-image"/>
                </div>
            </div>
            <div className="w-layout-grid grid-simple-about-two">
                <div id="w-node-_2094a67a-f47d-34e6-942d-ce10a353b14b-03618721" className="simple-about-image-wrap">
                    <img src={aboutImage07} loading="eager" alt="About Image" className="simple-about-image"/>
                </div>
                <div id="w-node-_2094a67a-f47d-34e6-942d-ce10a353b134-03618721" className="simple-about-content">
                    <h3>Engage Your Customers with Real-Time Community Updates</h3>
                    <p>Build a stronger connection with your customers through the Community feature, designed to act like a social media feed directly in your custom mobile app. Share real-time updates about upcoming events, special promotions, or new menu items.</p>
                    <div className="divider"></div>
                    <div className="simple-about-list">
                        <div class="hero-list-wrap">
                            <div className="simple-hero-list">
                                <img src={checkCircleIcon} loading="eager" alt="Check Icon" className="simple-hero-icon"/>
                                <div>Effortless POS Integration</div>
                            </div>
                            <div className="simple-hero-list">
                                <img src={checkCircleIcon} loading="eager" alt="Check Icon" className="simple-hero-icon"/>
                                <div>Real-Time Admin Dashboard</div>
                            </div>
                            <div className="simple-hero-list">
                                <img src={checkCircleIcon} loading="eager" alt="Check Icon" className="simple-hero-icon"/>
                            <div>Boost Customer Engagement</div>
                            </div>
                        </div>
                        <div className="hero-list-wrap">
                            <div className="simple-hero-list">
                                <img src={checkCircleIcon} loading="eager" alt="Check Icon" className="simple-hero-icon"/>
                                <div>Customizable & Scalable</div>
                            </div>
                            <div className="simple-hero-list">
                                <img src={checkCircleIcon} loading="eager" alt="Check Icon" className="simple-hero-icon"/>
                                <div>Secure transmission</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default FeaturesV4
