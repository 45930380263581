import playIcon from "../../../assets/images/defaults/6651d320cd11f6e370771d54_pause-icon.svg";
import playIcon03 from "../../../assets/images/defaults/6638c9ae40c7bff3b419af9e_play-icon3.svg";
import featureIcon04 from "../../../assets/images/defaults/6638cca301484c0ac756ab60_feature-icon-04.svg";
import featureIcon05 from "../../../assets/images/defaults/6638cca3eb75300c2c9df194_feature-icon-05.svg";
import featureIcon06 from "../../../assets/images/defaults/6638cca379c09420ccaeff02_feature-icon-06.svg";
import featureIcon07 from "../../../assets/images/defaults/6638cca3e4b1e6b3d89aa79d_feature-icon-07.svg";

const Demo = () => {

    return (
        <section style={{ paddingBottom: 120}} className="video-section">
            <div className="w-layout-blockcontainer container w-container">
                <div data-w-id="4debc486-2a90-87a6-bc22-647b33ce9d06"
                     style={{
                         opacity: 1,
                         textAlign: 'left',
                         transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                         transformStyle: 'preserve-3d'
                     }}
                     className="w-layout-grid grid-video">
                    <h2 id="w-node-_4debc486-2a90-87a6-bc22-647b33ce9d07-1715374c"
                        className="video-section-title">
                        Ready to Transform Your Restaurant? Let's Begin!
                    </h2>
                    <div id="w-node-_4debc486-2a90-87a6-bc22-647b33ce9d09-1715374c">
                        <p className="video-description">
                            Start your 30-day free trial today and see  how our customizable restaurant mobile app  can enhance your business. No credit card  required. Cancel anytime.
                        </p>
                        <a href="https://saspro-wbs.webflow.io/product-pages/request-a-demo"
                           className="button-primary-2 w-button">Request a Demo</a>
                    </div>
                </div>
                <div
                    data-poster-url="https://cdn.prod.website-files.com/6638734fc8194e46def84df4/6642f21b86c2b2ecdfb901fb_bg-video-poster-00001.jpg"
                    data-video-urls="https://cdn.prod.website-files.com/6638734fc8194e46def84df4/6642f21b86c2b2ecdfb901fb_bg-video-transcode.mp4,https://cdn.prod.website-files.com/6638734fc8194e46def84df4/6642f21b86c2b2ecdfb901fb_bg-video-transcode.webm"
                    data-autoplay="true" data-loop="true" data-wf-ignore="true"
                    data-w-id="4debc486-2a90-87a6-bc22-647b33ce9d0e"
                    style={{
                        opacity: 1,
                        transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                        transformStyle: 'preserve-3d'
                    }}
                    className="background-video w-background-video w-background-video-atom">
                    <video id="4debc486-2a90-87a6-bc22-647b33ce9d0e-video" autoPlay="" loop=""
                           style={{backgroundImage: '(url(https://cdn.prod.website-files.com/6638734fc8194e46def84df4/6642f21b86c2b2ecdfb901fb_bg-video-poster-00001.jpg&quot)'}}
                           muted="" playsInline="" data-wf-ignore="true" data-object-fit="cover">
                        <source
                            src="https://cdn.prod.website-files.com/6638734fc8194e46def84df4/6642f21b86c2b2ecdfb901fb_bg-video-transcode.mp4"
                            data-wf-ignore="true"/>
                        <source
                            src="https://cdn.prod.website-files.com/6638734fc8194e46def84df4/6642f21b86c2b2ecdfb901fb_bg-video-transcode.webm"
                            data-wf-ignore="true"/>
                    </video>
                    {/*<noscript>*/}
                    {/*    <style>*/}
                    {/*        [data-wf-bgvideo-fallback-img] {*/}
                    {/*        display: none;*/}
                    {/*    }*/}
                    {/*        @media (prefers-reduced-motion: reduce) {*/}
                    {/*        [data - wf - bgvideo - fallback - img] {*/}
                    {/*        position: absolute;*/}
                    {/*        z-index: -100;*/}
                    {/*        display: inline-block;*/}
                    {/*        height: 100%;*/}
                    {/*        width: 100%;*/}
                    {/*        object-fit: cover;*/}
                    {/*    }*/}
                    {/*    }*/}
                    {/*    </style>*/}
                    {/*    <img data-wf-bgvideo-fallback-img="true"*/}
                    {/*         src="https://cdn.prod.website-files.com/6638734fc8194e46def84df4/6642f21b86c2b2ecdfb901fb_bg-video-poster-00001.jpg"*/}
                    {/*         alt=""/>*/}
                    {/*</noscript>*/}
                    <div aria-live="polite">
                        <button type="button" data-w-bg-video-control="true"
                                aria-controls="4debc486-2a90-87a6-bc22-647b33ce9d0e-video"
                                className="w-backgroundvideo-backgroundvideoplaypausebutton play-pause-button w-background-video--control">
                                <span><img
                                    src={playIcon}
                                    loading="eager" alt="Pause video" className="play-icon"/></span><span hidden=""><img
                            loading="lazy" alt="Play video"
                            src={playIcon03}
                            className="pause-icon"/></span></button>
                    </div>
                </div>
                {/*<div className="w-layout-grid grid-feature">*/}
                {/*    <div id="w-node-_4debc486-2a90-87a6-bc22-647b33ce9d15-1715374c"*/}
                {/*         data-w-id="4debc486-2a90-87a6-bc22-647b33ce9d15"*/}
                {/*         style={{*/}
                {/*             opacity: 1,*/}
                {/*             textAlign: 'left',*/}
                {/*             transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',*/}
                {/*             transformStyle: 'preserve-3d'*/}
                {/*         }}*/}
                {/*         className="feature-item-box">*/}
                {/*        <div className="feature-icon-box-wrap bg-primary-2"><img*/}
                {/*            src={featureIcon04}*/}
                {/*            loading="lazy" alt="Feature Icon" className="feature-box-icon"/></div>*/}
                {/*        <h3 className="heading-h6">Smart automation</h3>*/}
                {/*        <p className="feature-description">Our intelligent solutions automate repetitive tasks,*/}
                {/*            freeing up valuable time for your team.</p>*/}
                {/*    </div>*/}
                {/*    <div id="w-node-_4debc486-2a90-87a6-bc22-647b33ce9d1c-1715374c"*/}
                {/*         data-w-id="4debc486-2a90-87a6-bc22-647b33ce9d1c"*/}
                {/*         style={{*/}
                {/*             opacity: 1,*/}
                {/*             textAlign: 'left',*/}
                {/*             transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',*/}
                {/*             transformStyle: 'preserve-3d'*/}
                {/*         }}*/}
                {/*         className="feature-item-box">*/}
                {/*        <div className="feature-icon-box-wrap bg-secondary-1"><img*/}
                {/*            src={featureIcon05}*/}
                {/*            loading="lazy" alt="Feature Icon" className="feature-box-icon"/></div>*/}
                {/*        <h4 className="heading-h6">Predictive analytics</h4>*/}
                {/*        <p className="feature-description">Gain valuable insights into your environment with*/}
                {/*            predictive analytics.</p>*/}
                {/*    </div>*/}
                {/*    <div id="w-node-_4debc486-2a90-87a6-bc22-647b33ce9d23-1715374c"*/}
                {/*         data-w-id="4debc486-2a90-87a6-bc22-647b33ce9d23"*/}
                {/*         style={{*/}
                {/*             opacity: 1,*/}
                {/*             textAlign: 'left',*/}
                {/*             transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',*/}
                {/*             transformStyle: 'preserve-3d'*/}
                {/*         }}*/}
                {/*         className="feature-item-box">*/}
                {/*        <div className="feature-icon-box-wrap bg-primary-3"><img*/}
                {/*            src={featureIcon06}*/}
                {/*            loading="lazy" alt="Feature Icon" className="feature-box-icon"/></div>*/}
                {/*        <h5 className="heading-h6">Adaptive learning</h5>*/}
                {/*        <p className="feature-description">Our intelligent solutions continuously learn and adapt to*/}
                {/*            your environment.</p>*/}
                {/*    </div>*/}
                {/*    <div id="w-node-_4debc486-2a90-87a6-bc22-647b33ce9d2a-1715374c"*/}
                {/*         data-w-id="4debc486-2a90-87a6-bc22-647b33ce9d2a"*/}
                {/*         style={{*/}
                {/*             opacity: 1,*/}
                {/*             textAlign: 'left',*/}
                {/*             transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',*/}
                {/*             transformStyle: 'preserve-3d'*/}
                {/*         }}*/}
                {/*         className="feature-item-box">*/}
                {/*        <div className="feature-icon-box-wrap bg-primary-1"><img*/}
                {/*            src={featureIcon07}*/}
                {/*            loading="lazy" alt="Feature Icon" className="feature-box-icon"/></div>*/}
                {/*        <h6 className="heading-h6">Intelligent optimization</h6>*/}
                {/*        <p className="feature-description">Enhance the performance of your systems and processes*/}
                {/*            with intelligent optimization.</p>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </section>
    )

}

export default Demo;
