import testimonialIcon01 from "../../../assets/images/defaults/6641b94df7dbcda617d071b4_Vector-1.svg";
import testimonialDecoration03 from "../../../assets/images/defaults/6641b35fbdafbc2bc9b06b24_decoration-03-1.svg";
import testimonialStarImage from "../../../assets/images/defaults/6638b0e7ceec979a4b9f1ac8_star-image.svg";
import chevronLeft from "../../../assets/images/defaults/6639df6c358cb6821ed2c127_chevron-left.svg";
import chevronRight from "../../../assets/images/defaults/6639df6cc8f517b790972fe9_chevron-right.svg";
import avatar01 from "../../../assets/images/defaults/6639dce81cdbf90fc4369359_avtar-01.jpg"
import avatar02 from "../../../assets/images/defaults/6639dce891abcef85173321d_avtar-02.jpg"
import avatar03 from "../../../assets/images/defaults/6639dce8d3977e4843b1d52a_avtar-03.jpg"
import {useState} from "react";

const Testimonials = () => {

    const [currentScrollIndex, setCurrentScrollIndex] = useState(0);

    return (
        <section data-w-id="694141c4-a362-7266-2afe-21a403acf525"
                 style={{
                     opacity: 1,
                     transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                     transformStyle: 'preserve-3d'
                 }}
                 className="testimonial-section">
            <img src={testimonialIcon01}
                 loading="eager" alt="Decoration" className="testimonial-decoration-one"/><img
            src={testimonialDecoration03}
            loading="eager" alt="Decoration" className="testimonial-decoration-two"/>
            <div className="w-layout-blockcontainer container w-container">
                <div data-w-id="694141c4-a362-7266-2afe-21a403acf529"
                     style={{
                         opacity: 1,
                         textAlign: 'left',
                         transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                         transformStyle: 'preserve-3d'
                     }}
                     className="section-title">
                    <h2 className="testimonial-section-title">Customer testimonials</h2>
                    <div className="avatar-image-wrapper">
                        <div className="avatar-image-wrap">
                            <img
                            src={avatar01}
                            loading="eager" alt="Avatar Image" className="avatar-image left-0"/>
                            <img
                            src={avatar02}
                            loading="eager" alt="Avatar Image" className="avatar-image"/>
                            <img
                            src={avatar03}
                            loading="eager" alt="Avatar Image" className="avatar-image"/>
                        </div>
                        <p className="avatar-description">Our product has a positive impact on our customers'
                            operations, which they love.</p>
                    </div>
                </div>
                <div data-delay="4000" data-animation="slide" className="testimonial-slider w-slider"
                     data-autoplay="false" data-easing="ease" data-hide-arrows="false" data-disable-swipe="false"
                     data-autoplay-limit="0" data-nav-spacing="3" data-duration="500" data-infinite="true"
                     role="region" aria-label="carousel">
                    <div style={{left: currentScrollIndex === 0 ? 0 : -900}} className="testimonial-mask w-slider-mask" id="w-slider-mask-0">
                        <div className="testimonial-slide w-slide" aria-label="1 of 5" role="group"
                             style={{transition: 'all', transform: 'translateX(0px); opacity: 1'}}>
                            <div className="testimonial-item">
                                <img loading="eager"
                                     src={testimonialStarImage}
                                     alt="Star Image" className="testimonial-image"/>
                                <p className="testimonial-description">The user experience with the software we
                                    implemented is unparalleled. Its intuitive interface and seamless
                                    navigation.</p>
                                <div className="testimonial-info">
                                    <h3 className="testimonial-title">Jacqueline Miller</h3>
                                    <div className="testimonial-name">Web developer</div>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-slide w-slide" aria-label="2 of 5" role="group"
                             aria-hidden="true" style={{transition: 'all', transform: 'translateX(0px)', opacity: 1}}>
                            <div className="testimonial-item" aria-hidden="true">
                                <img loading="eager"
                                     src={testimonialStarImage}
                                     alt="Star Image" className="testimonial-image" aria-hidden="true"/>
                                <p className="testimonial-description" aria-hidden="true">Thanks to the software
                                    solution we adopted, we've been able to reclaim valuable time that was
                                    previously spent on manual tasks.</p>
                                <div className="testimonial-info" aria-hidden="true">
                                    <h4 className="testimonial-title" aria-hidden="true">Louis Ferguson</h4>
                                    <div className="testimonial-name" aria-hidden="true">Product designer</div>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-slide w-slide" aria-label="3 of 5" role="group"
                             aria-hidden="true" style={{transition: 'all', transform: 'translateX(0px)', opacity: 1}}>
                            <div className="testimonial-item" aria-hidden="true">
                                <img loading="eager"
                                     src={testimonialStarImage}
                                     alt="Star Image" className="testimonial-image" aria-hidden="true"/>
                                <p className="testimonial-description" aria-hidden="true">The impact of the software
                                    on our business has been nothing short of transformational. It has
                                    revolutionized.</p>
                                <div className="testimonial-info" aria-hidden="true">
                                    <h5 className="testimonial-title" aria-hidden="true">Joan Wallace</h5>
                                    <div className="testimonial-name" aria-hidden="true">UI\UX designer</div>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-slide w-slide" aria-label="4 of 5" role="group"
                             aria-hidden="true" style={{transition: 'all', transform: 'translateX(0px)', opacity: 1}}>
                            <div className="testimonial-item" aria-hidden="true">
                                <img loading="eager"
                                     src={testimonialStarImage}
                                     alt="Star Image" className="testimonial-image" aria-hidden="true"/>
                                <p className="testimonial-description" aria-hidden="true">Reliability is
                                    non-negotiable when it comes to software. We haven't experienced any downtime
                                    since implementing the software.</p>
                                <div className="testimonial-info" aria-hidden="true">
                                    <h6 className="testimonial-title" aria-hidden="true">Amanda Reed</h6>
                                    <div className="testimonial-name" aria-hidden="true">Graphic designer</div>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-slide w-slide" aria-label="5 of 5" role="group"
                             aria-hidden="true" style={{transition: 'all', transform: 'translateX(0px)', opacity: 1}}>
                            <div className="testimonial-item" aria-hidden="true">
                                <img loading="eager"
                                     src={testimonialStarImage}
                                     alt="Star Image" className="testimonial-image" aria-hidden="true"/>
                                <p className="testimonial-description" aria-hidden="true">Finding a cost-effective
                                    solution without compromising on quality can be challenging, software we chose
                                    checks all the boxes.</p>
                                <div className="testimonial-info" aria-hidden="true">
                                    <h6 className="testimonial-title" aria-hidden="true">Dennis Barrett</h6>
                                    <div className="testimonial-name" aria-hidden="true">Co-founder</div>
                                </div>
                            </div>
                        </div>
                        <div aria-live="off" aria-atomic="true" className="w-slider-aria-label"
                             data-wf-ignore=""></div>
                    </div>
                    <div style={{ cursor: 'pointer' }} onClick={() => setCurrentScrollIndex(0)} className="left-arrow w-slider-arrow-left" role="button" tabIndex="0"
                         aria-controls="w-slider-mask-0" aria-label="previous slide">
                        <img loading="lazy" src={chevronLeft} alt="Chevron Icon" className="left-icon"/>
                    </div>
                    <div style={{ cursor: 'pointer' }} onClick={() => setCurrentScrollIndex(1)} className="right-arrow w-slider-arrow-right" role="button" tabIndex="0"
                         aria-controls="w-slider-mask-0" aria-label="next slide">
                        <img loading="lazy" src={chevronRight} alt="Chevron Icon" className="right-icon"/>
                    </div>
                    <div className="hide w-slider-nav w-round">
                        <div className="w-slider-dot w-active" data-wf-ignore="" aria-label="Show slide 1 of 5"
                             aria-pressed="true" role="button" tabIndex="0"
                             style={{marginLeft: '3px', marginRight: '3px'}}></div>
                        <div className="w-slider-dot" data-wf-ignore="" aria-label="Show slide 2 of 5"
                             aria-pressed="false" role="button" tabIndex="-1"
                             style={{marginLeft: '3px', marginRight: '3px'}}></div>
                        <div className="w-slider-dot" data-wf-ignore="" aria-label="Show slide 3 of 5"
                             aria-pressed="false" role="button" tabIndex="-1"
                             style={{marginLeft: '3px', marginRight: '3px'}}></div>
                        <div className="w-slider-dot" data-wf-ignore="" aria-label="Show slide 4 of 5"
                             aria-pressed="false" role="button" tabIndex="-1"
                             style={{marginLeft: '3px', marginRight: '3px'}}></div>
                        <div className="w-slider-dot" data-wf-ignore="" aria-label="Show slide 5 of 5"
                             aria-pressed="false" role="button" tabIndex="-1"
                             style={{marginLeft: '3px', marginRight: '3px'}}></div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Testimonials;
