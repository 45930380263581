import decoration03 from "../../../assets/images/defaults/6641b35fbdafbc2bc9b06b24_decoration-03-1.svg";
import vector from "../../../assets/images/defaults/6641b94df7dbcda617d071b4_Vector-1.svg";
import icon04 from "../../../assets/images/defaults/6638cca301484c0ac756ab60_feature-icon-04.svg";
import icon05 from "../../../assets/images/defaults/6638cca3eb75300c2c9df194_feature-icon-05.svg";
import icon06 from "../../../assets/images/defaults/6638cca379c09420ccaeff02_feature-icon-06.svg";
import icon07 from "../../../assets/images/defaults/6638cca3e4b1e6b3d89aa79d_feature-icon-07.svg"

const FeaturesV2 = () => {

    return (
        <section className="feature-dark-section">
            <div className="w-layout-blockcontainer container w-container">
                <div className="w-layout-grid grid-feature">
                    <div id="w-node-_6a788126-d498-2f13-7e7d-fd64ea7b8c13-29356f8a"
                         data-w-id="6a788126-d498-2f13-7e7d-fd64ea7b8c13"
                         style={{
                             opacity: 1,
                             transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                             transformStyle: 'preserve-3d'
                         }}
                         className="feature-item-box">
                        <div className="feature-icon-box-wrap bg-primary-2"><img
                            src={icon04}
                            loading="lazy" alt="Feature Icon" className="feature-box-icon"/></div>
                        <h5 className="text-white heading-h6">Smart automation</h5>
                        <p>Our intelligent solutions automate repetitive tasks, freeing up valuable time for your
                            team.</p>
                    </div>
                    <div id="w-node-_6a788126-d498-2f13-7e7d-fd64ea7b8c1a-29356f8a"
                         data-w-id="6a788126-d498-2f13-7e7d-fd64ea7b8c1a"
                         style={{
                             opacity: 1,
                             transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                             transformStyle: 'preserve-3d'
                         }}
                         className="feature-item-box">
                        <div className="feature-icon-box-wrap bg-secondary-1"><img
                            src={icon05}
                            loading="lazy" alt="Feature Icon" className="feature-box-icon"/></div>
                        <h6 className="text-white">Predictive analytics</h6>
                        <p>Gain valuable insights into your environment with predictive analytics.</p>
                    </div>
                    <div id="w-node-_6a788126-d498-2f13-7e7d-fd64ea7b8c21-29356f8a"
                         data-w-id="6a788126-d498-2f13-7e7d-fd64ea7b8c21"
                         style={{
                             opacity: 1,
                             transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                             transformStyle: 'preserve-3d'
                         }}
                         className="feature-item-box">
                        <div className="feature-icon-box-wrap bg-primary-3"><img
                            src={icon06}
                            loading="lazy" alt="Feature Icon" className="feature-box-icon"/></div>
                        <h6 className="text-white">Adaptive learning</h6>
                        <p>Our intelligent solutions continuously learn and adapt to your environment.</p>
                    </div>
                    <div id="w-node-_6a788126-d498-2f13-7e7d-fd64ea7b8c28-29356f8a"
                         data-w-id="6a788126-d498-2f13-7e7d-fd64ea7b8c28"
                         style={{
                             opacity: 1,
                             transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                             transformStyle: 'preserve-3d'
                         }}
                         className="feature-item-box">
                        <div className="feature-icon-box-wrap bg-primary-1"><img
                            src={icon07}
                            loading="lazy" alt="Feature Icon" className="feature-box-icon"/></div>
                        <h6 className="text-white">Intelligent optimization</h6>
                        <p>Enhance the performance of your systems and processes with intelligent optimization.</p>
                    </div>
                </div>
            </div>
            <img
                src={decoration03}
                loading="eager" alt="Decoration" className="feature-dark-decoration-one"/><img
            src={vector}
            loading="eager" alt="Decoration" className="feature-dark-decoration-two"/>
        </section>
    )
}

export default FeaturesV2
