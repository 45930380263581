

import Features from "./Components/Features";
import Hero from "./Components/Hero";
import Demo from "./Components/Demo";
import Testimonials from "./Components/Testimonials";
import Resources from "./Components/Resources";
import FeaturesV2 from "./Components/FeaturesV2";
import FeaturesV3 from "./Components/FeaturesV3";
import FeaturesV4 from "./Components/FeaturesV4";

const Homepage = () => {

    return(
        <>
            {/*<DarkNavBar page={''}/>*/}
            <Hero/>
            {/* NEW */}
            <FeaturesV3 />
            <Features/>
            <FeaturesV4 />

            {/* <FeaturesV2 /> */}
            <Demo/>
            <Testimonials/>
            <Resources/>
        </>
    )

}

export default Homepage
