const PrivacyPolicyPage = () => {

    return (
        <>
            <div className="hero-inner text-center">
                <div className="container w-container">
                    <h1>Privacy Policy</h1>
                </div>
            </div>
            <div className="privacy-section section-spacing-sm-top">
                <div className="container-medium w-container">
                    <div data-w-id="51506210-a64a-fa97-8e8d-e5a7be8b24c2"
                         style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}
                         className="privacy-wrap">
                        <div className="rich-text w-richtext">
                            <h2 className="heading-h5">Collecting Personal Information<br/></h2>
                            <p>All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as
                                necessary, making this the first true generator on the Internet. It uses a dictionary of
                                over 200 Latin words, combined with a handful of model sentence structures, to generate
                                Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free
                                from repetition, injected humour, or non-characteristic words etc.</p>
                            <ul role="list">
                                <li>There are many variations of passages of Lorem Ipsum available.</li>
                                <li>Iusto odio dignissimos ducimus qui blanditiis.</li>
                                <li>Praesentium voluptatum deleniti atque.</li>
                                <li>Quas molestias excepturi sint occaecati.</li>
                            </ul>
                            <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those
                                interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" <em>by
                                    Cicero are also reproduced in their exact original form</em>, accompanied by English
                                versions from the 1914 translation by H. Rackham.</p>
                            <h3 className="heading-h5">Sharing Personal Information</h3>
                            <p>There are many variations of passages of Lorem Ipsum available, but the majority have
                                suffered alteration in some form, by injected humour, or randomised words which don't
                                look even slightly believable. If you are going to use a passage of Lorem Ipsum, you
                                need to be sure there isn't anything embarrassing <strong>hidden in the middle of
                                    text.</strong> All the Lorem Ipsum generators on the Internet tend to repeat
                                predefined chunks as necessary, making this the first true generator on the Internet. It
                                uses a dictionary of over 200 Latin words, combined with a handful of model sentence
                                structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is
                                therefore always free from repetition, injected humour, or non-characteristic words etc.
                            </p>
                            <p>It was popularised in the 1960s with the release of Letraset sheets containing Lorem
                                Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker
                                including versions of Lorem Ipsum.</p>
                            <ol start="" role="list">
                                <li>sometimes on purpose.</li>
                                <li>classical Latin literature from 45 BC.</li>
                                <li>The Extremes of Good and Evil.</li>
                                <li>This book is a treatise on the theory.</li>
                            </ol>
                            <p>Combined with a handful of model sentence structures, to generate Lorem Ipsum which looks
                                reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected
                                humour, or non-characteristic words etc.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default PrivacyPolicyPage
