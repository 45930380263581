import icon01 from "../../../assets/images/defaults/6638bfdbd62c09a42b929503_feature-icon-01.svg"
import icon02 from "../../../assets/images/defaults/665411e7ca6ef20d21a73404_feature-icons.svg"
import icon03 from "../../../assets/images/defaults/663b27e3938baeb7955afda3_feature-icon-03.svg"

const DEFAULT_FEATURES = [
    {index: 0, bg: 'bg-secondary-3', icon_bg: 'bg-secondary-2', text: '', title: 'Real-Time Menu & Item Updates', content: 'Manage and modify your menu, add items, or  adjust pricing instantly from the admin  dashboard.', icon: icon01},
    {index: 1, bg: 'bg-primary-3', icon_bg: 'bg-white', text: 'text-white', title: 'Multiple Locations, One App', content: 'Operate  multiple restaurant locations with ease,  managing them all from one centralized  platform.', icon: icon02},
    {index: 2, bg: 'bg-secondary-3', icon_bg: 'bg-secondary-1', text: '', title: 'Push Notifications & Promotions', content: 'Rest assured knowing that your data is safe and secure with our robust security measures.', icon: icon03},
    // {index: 3, bg: 'bg-secondary-2', title: 'Customizable Rewards Program', content: 'Create  and manage a rewards program that keeps  customers coming back. Fully customizable to  suit your brand.'},
    // {index: 4, bg: 'bg-secondary-1', title: 'Instant Theme Changes', content: 'Update the look  and feel of your app with real-time theme  adjustments from the admin dashboard.  Reflect seasonal themes or update branding  instantly.'},
    // {index: 5, bg: 'bg-primary-3', title: '30-Day Free Trial', content: 'Test-drive the app  with no commitment. Experience firsthand  how our platform can simplify operations and  drive customer loyalty.' },
]

const FeaturesV3 = () => {

    return (
        <>
            <section className="feature-four-section section-spacing">
                <div className="w-layout-blockcontainer container w-container">
                    <div data-w-id="fde86466-c2b3-3e1d-567d-961580149577" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="feature-four-section-title-wrap">
                        <h2 className="feature-four-section-title">Key Features Built for Restaurant Success</h2>
                        <p className="no-margin">Your Custom Mobile App: Seamlessly Integrate Promotions, Loyalty Rewards, and More with Your POS for Unmatched Restaurant Success.</p>
                    </div>
                    <div className="w-layout-grid grid-feature-four">
                        {DEFAULT_FEATURES.map((feature, index) => (
                            <div id="w-node-_5e23be2f-22ef-f963-eaea-d0e22abcfd2a-03618721" data-w-id="5e23be2f-22ef-f963-eaea-d0e22abcfd2a" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className={`feature-four-item ${feature?.bg}`}>
                                <div className={`feature-icon-wrap ${feature?.icon_bg}`}>
                                    <img src={feature?.icon} loading="lazy" alt="Feature Icon" className="feature-icon"/>
                                </div>
                                <div>
                                    <h3 className={`feature-title ${feature?.text}`}>{feature?.title} </h3>
                                    <p className={`no-margin ${feature?.text}`}>{feature?.content}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )

};

export default FeaturesV3
