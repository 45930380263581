import Footer from "../components/Footer";
import AlternateNavBar from "../components/AlternateNavBar";
import {Outlet} from "react-router-dom";

const HelpCenterLayout = ({ children }) => {

    return (
        <div>
            <AlternateNavBar/>
            <main>
                <Outlet/> {/* This is where child routes will be rendered */}
            </main>
            <Footer/>
        </div>
    )

}

export default HelpCenterLayout
