import decoration01 from "../../assets/images/defaults/6641b344baa1ededaa5b27dd_decoration-01-1.svg";
import decoration03 from "../../assets/images/defaults/6641b35fbdafbc2bc9b06b24_decoration-03-1.svg";
import Hero from "./Components/Hero";
import Integrations from "./Components/Integrations";

const RequestDemo = () => {

    return(
        <>
            {/*<DarkNavBar page={''} />*/}
            <section className="request-demo-section">
                <Hero />
                <Integrations />
            </section>
        </>
    )

}

export default RequestDemo;
