import ArticleContent from "./Components/ArticleContent";
import PopularArticles from "../HelpCenter/Components/PopularArticles";
import RelatedArticles from "./Components/RelatedArticles";

const HelpArticlePage = () => {

    return (
        <>
            <ArticleContent />
            <RelatedArticles />
        </>
    )
}

export default HelpArticlePage
