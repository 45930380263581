import {useState} from "react";

const Faq = () => {

    const [selectedIndicies, setSelectedIndicies] = useState([]);

    function toggleSelection(index: Number) {
        if (selectedIndicies.includes(index)) {
            let parsed = selectedIndicies.filter((i) => i !== index);
            setSelectedIndicies(parsed);
        } else {
            let parsed = selectedIndicies.concat([index])
            console.log(parsed)
            setSelectedIndicies(parsed);
        }
    }

    return (
        <section className="split-faqs-section">
            <div style={{textAlign: 'left'}} className="w-layout-blockcontainer container w-container">
                <div id="w-node-ac6c9811-5b11-13a4-76b9-a9aec2d35c08-f88a5567"
                     className="section-title text-center">
                    <h2 data-w-id="438bc5c0-3cc8-00b2-bd72-86d61c38b7dc"
                        style={{
                            opacity: 1,
                            transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d'
                        }}>Frequently
                        asked &nbsp;questions</h2>
                </div>
                <div data-w-id="438bc5c0-3cc8-00b2-bd72-86d61c38b7db"
                     style={{
                         opacity: 1,
                         transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                         transformStyle: 'preserve-3d'
                     }}
                     className="w-layout-grid grid-split-faqs-items">
                    <div id="w-node-_88670485-21f3-7553-d00d-0973e1a721f9-f88a5567"
                         className="simple-feature-image-wrap"><img
                        src="./Pricing 3 _ SasPro - Webflow Ecommerce website template_files/66445e71f960c564b4062861_feature-image-09.jpg"
                        loading="eager"
                        sizes="(max-width: 479px) 94vw, (max-width: 767px) 95vw, (max-width: 991px) 96vw, (max-width: 1279px) 95vw, (max-width: 1919px) 96vw, 1400px"
                        srcSet="https://cdn.prod.website-files.com/6638734fc8194e46def84df4/66445e71f960c564b4062861_feature-image-09-p-500.jpg 500w, https://cdn.prod.website-files.com/6638734fc8194e46def84df4/66445e71f960c564b4062861_feature-image-09.jpg 800w"
                        alt="Feature Image" className="simple-feature-image"/><img
                        src="./Pricing 3 _ SasPro - Webflow Ecommerce website template_files/663a15fe2a660bc2e5ce7869_dashboard-02.svg"
                        loading="eager" alt="Dashboard" className="faqs-decoration"/></div>
                    <div id="w-node-_438bc5c0-3cc8-00b2-bd72-86d61c38b7de-f88a5567">
                        <div>
                            <div style={{backgroundColor: 'rgb(255, 255, 255)'}} className="accordion-wrap">
                                <div onClick={() => toggleSelection(0)} data-w-id="654a180b-13be-064a-7233-98f1134e75de" className="accordion-heading">
                                    <h3 className="accordion-title">Can I integrate your product with other
                                        tools?</h3>
                                    <div className="accordion-icon-wrap">
                                        <div className="accordion-icon-plus"></div>
                                        <div
                                            style={{
                                                transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(90deg) skew(0deg, 0deg)',
                                                transformStyle: 'preserve-3d'
                                            }}
                                            className="accordion-icon-minus"></div>
                                    </div>
                                </div>
                                <div style={{height: selectedIndicies.includes(0) ? 'auto' : '0px'}} className="accordion-content-wrap">
                                    <p className="accordion-content">Many SaaS solutions offer integration
                                        capabilities, allowing users to connect with various applications and
                                        services to streamline workflows and enhance productivity. Check our
                                        integration documentation or contact support for specific details on
                                        compatibility.</p>
                                </div>
                                <div style={{opacity: 0.1}} className="faqs-divider"></div>
                            </div>
                            <div style={{backgroundColor: 'rgb(255, 255, 255)'}} className="accordion-wrap">
                                <div onClick={() => toggleSelection(1)} data-w-id="654a180b-13be-064a-7233-98f1134e75e9" className="accordion-heading">
                                    <h4 className="accordion-title">Is there customer support available?</h4>
                                    <div className="accordion-icon-wrap">
                                        <div className="accordion-icon-plus"></div>
                                        <div
                                            style={{
                                                transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(90deg) skew(0deg, 0deg)',
                                                transformStyle: 'preserve-3d'
                                            }}
                                            className="accordion-icon-minus"></div>
                                    </div>
                                </div>
                                <div style={{height: selectedIndicies.includes(1) ? 'auto' : '0px'}} className="accordion-content-wrap">
                                    <p className="accordion-content">Yes, most SaaS providers offer customer support
                                        via multiple channels, such as email, live chat, or phone, to assist users
                                        with queries, technical issues, or guidance on using the software
                                        effectively.</p>
                                </div>
                                <div style={{opacity: 0.1}} className="faqs-divider"></div>
                            </div>
                            <div style={{backgroundColor: 'rgb(255, 255, 255)'}} className="accordion-wrap">
                                <div onClick={() => toggleSelection(2)} data-w-id="654a180b-13be-064a-7233-98f1134e75f4" className="accordion-heading">
                                    <h5 className="accordion-title">Is there a free trial available?</h5>
                                    <div className="accordion-icon-wrap">
                                        <div className="accordion-icon-plus"></div>
                                        <div
                                            style={{
                                                transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(90deg) skew(0deg, 0deg)',
                                                transformStyle: 'preserve-3d'
                                            }}
                                            className="accordion-icon-minus"></div>
                                    </div>
                                </div>
                                <div style={{height: selectedIndicies.includes(2) ? 'auto' : '0px'}} className="accordion-content-wrap">
                                    <p className="accordion-content">Yes, we offer a 3 month free trial period for
                                        users to experience our product/service. Sign up on our website to get
                                        started.</p>
                                </div>
                                <div style={{opacity: 0.1}} className="faqs-divider"></div>
                            </div>
                            <div style={{backgroundColor: 'rgb(255, 255, 255)'}} className="accordion-wrap">
                                <div onClick={() => toggleSelection(3)} data-w-id="654a180b-13be-064a-7233-98f1134e75ff" className="accordion-heading">
                                    <h6 className="accordion-title">How can I upgrade or downgrade my subscription
                                        plan?</h6>
                                    <div className="accordion-icon-wrap">
                                        <div className="accordion-icon-plus"></div>
                                        <div
                                            style={{
                                                transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(90deg) skew(0deg, 0deg)',
                                                transformStyle: 'preserve-3d'
                                            }}
                                            className="accordion-icon-minus"></div>
                                    </div>
                                </div>
                                <div style={{height: selectedIndicies.includes(3) ? 'auto' : '0px'}} className="accordion-content-wrap">
                                    <p className="accordion-content">You can easily upgrade or downgrade your
                                        subscription plan through your account settings. If you need assistance,
                                        contact our support team for guidance.</p>
                                </div>
                                <div style={{opacity: 0.1}} className="faqs-divider"></div>
                            </div>
                            <div style={{backgroundColor: 'rgb(255, 255, 255)'}} className="accordion-wrap">
                                <div onClick={() => toggleSelection(4)} data-w-id="654a180b-13be-064a-7233-98f1134e760a" className="accordion-heading">
                                    <h6 className="accordion-title">How can I protect my software from viruses and
                                        malware?</h6>
                                    <div className="accordion-icon-wrap">
                                        <div className="accordion-icon-plus"></div>
                                        <div
                                            style={{
                                                transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(90deg) skew(0deg, 0deg)',
                                                transformStyle: 'preserve-3d'
                                            }}
                                            className="accordion-icon-minus"></div>
                                    </div>
                                </div>
                                <div style={{height: selectedIndicies.includes(4) ? 'auto' : '0px'}} className="accordion-content-wrap">
                                    <p className="accordion-content">To protect your software from viruses and
                                        malware, install reputable antivirus software, keep your operating system
                                        and applications up to date, avoid downloading software from untrusted
                                        sources, and regularly scan your system for threats.</p>
                                </div>
                                <div style={{opacity: 0.1}} className="faqs-divider"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-w-id="7e391ab5-1c99-7d75-37ef-ff629ce3f174"
                     style={{
                         opacity: 1,
                         transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                         transformStyle: 'preserve-3d'
                     }}
                     className="cta-wrapper">
                    <h5 className="cta-title">Have more questions? Book a free strategy call.</h5>
                    <a href="https://saspro-wbs.webflow.io/help-pages/contact"
                       className="button-primary-2 w-button">Book a call</a>
                </div>
            </div>
        </section>
    )
}

export default Faq;
