import featureImage07 from "../../../assets/images/defaults/6641b73dddd1ae4058deea6a_feature-image-07.jpg";
import dashboard03 from "../../../assets/images/defaults/663b407a2d660f23fadb5018_dashboard-03.svg";
import dashboard04 from "../../../assets/images/defaults/663b407a666b438c070adf8f_dashboard-04.svg";

const FeaturesV1 = () => {

    return (
        <section className="feature-left-right-section section-spacing">
            <div className="w-layout-blockcontainer container-medium w-container">
                <div className="section-title text-center">
                    <h2 data-w-id="9be753ff-6bf0-2fff-6cf3-34592a99a837"
                        style={{
                            opacity: 1,
                            transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d'
                        }}>The
                        core of our approach</h2>
                </div>
                <div data-w-id="94b30d09-b4a5-9104-4f16-523efb53fe42"
                     style={{
                         opacity: 1,
                         transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                         transformStyle: 'preserve-3d'
                     }}
                     className="w-layout-grid grid-features-one">
                    <div id="w-node-a54ff72b-24b4-fba4-9102-db534c631682-29356f8a"
                         className="feature-one-image-wrapper"><img
                        src={featureImage07}
                        loading="eager"
                        sizes="(max-width: 479px) 94vw, (max-width: 991px) 96vw, (max-width: 1279px) 94vw, 1140px"
                        srcSet="https://cdn.prod.website-files.com/6638734fc8194e46def84df4/6641b73dddd1ae4058deea6a_feature-image-07-p-500.jpg 500w, https://cdn.prod.website-files.com/6638734fc8194e46def84df4/6641b73dddd1ae4058deea6a_feature-image-07.jpg 800w"
                        alt="Feature Image" className="feature-one-images"/></div>
                    <div id="w-node-_167036c7-0968-b562-0637-ca9e4368e656-29356f8a">
                        <h3 className="feature-left-title">Detailed data categorization</h3>
                        <p>Dive deep into your data. Explore our comprehensive segmentation capabilities, allowing
                            you to analyze specific subsets of your data for precise insights.</p>
                    </div>
                </div>
                <div data-w-id="7961a783-8e59-7bc3-8caf-87932b910b42"
                     style={{
                         opacity: 1,
                         transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                         transformStyle: 'preserve-3d'
                     }}
                     className="w-layout-grid grid-features-two">
                    <div id="w-node-_7961a783-8e59-7bc3-8caf-87932b910b45-29356f8a">
                        <h4 className="feature-right-title">Tailored solutions for your specific requirements</h4>
                        <p>Tailor your data analytics experience. Discover how our tool can be customized to meet
                            your unique reporting and analysis requirements.</p>
                    </div>
                    <div className="feature-two-image-wrap"><img
                        src={dashboard03}
                        loading="eager" alt="Dashboard" className="feature-two-image"/></div>
                </div>
                <div data-w-id="e4cbd817-c2c8-ad79-c755-2c0bba2d6958"
                     style={{
                         opacity: 1,
                         transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                         transformStyle: 'preserve-3d'
                     }}
                     className="w-layout-grid grid-features-three">
                    <div id="w-node-cc28966d-5974-1a37-f24a-6a6982c5f4f6-29356f8a"
                         className="feature-three-image-wrapper"><img
                        src={dashboard04}
                        loading="eager" alt="Dashboard" className="feature-three-images"/></div>
                    <div id="w-node-e4cbd817-c2c8-ad79-c755-2c0bba2d695b-29356f8a">
                        <h4 className="feature-left-title">Streamlining data sharing effortlessly</h4>
                        <p>Collaborate seamlessly with your team. Our tool facilitates data sharing, commenting, and
                            collaborative reporting, so you can work together effectively.</p>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default FeaturesV1;
