import twitterIcon from "../assets/images/defaults/6638a05d3354265fe04aba2e_twitter.svg"
import facebookIcon from "../assets/images/defaults/6638a05dd5399022901e54ba_facebook.svg"
import instagramIcon from "../assets/images/defaults/6638a05d1294f0ad48f610d1_instagram.svg"
import linkedInIcon from "../assets/images/defaults/6638a05e6d839c90ffbea65b_linkedin.svg"
import unknownImage from "../assets/images/defaults/6641b94df7dbcda617d071b4_Vector-1.svg"
import {useLocation} from "react-router-dom";

const Footer = () => {

    const { pathname } = useLocation();

    return (
        <footer data-w-id="9807520c-65b7-e828-71bd-909a6cfe1801" className="footer"
                style={{
                    opacity: 1,
                    transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                    transformStyle: 'preserve-3d'
                }}>
            <div className="container w-container">
                <div style={{textAlign: 'left'}} className="footer-box">
                    <div id="w-node-_9807520c-65b7-e828-71bd-909a6cfe1804-6cfe1801" className="footer-wrap">
                        <div id="w-node-_9807520c-65b7-e828-71bd-909a6cfe182b-6cfe1801"
                             data-w-id="9807520c-65b7-e828-71bd-909a6cfe182b" className="footer-item"
                             style={{opacity: 1}}>
                            <div className="subscribe-title-wrap">
                                <h3 className="subscribe-title">Signup for the latest update</h3>
                                <p className="footer-description">Get exclusive insights, trend forecasts, and
                                    innovative strategies delivered directly to your inbox.</p>
                                <div className="form-subscribe-wrap w-form">
                                    <form id="wf-form-Subscribe-Form" name="wf-form-Subscribe-Form"
                                          data-name="Subscribe Form" method="get" className="form-subscribe"
                                          data-wf-page-id="6646e91ac79fdaa61715374c"
                                          data-wf-element-id="9807520c-65b7-e828-71bd-909a6cfe1832"
                                          aria-label="Subscribe Form"><input
                                        className="form-input form-input-subscribe w-input" maxLength="256"
                                        name="Email" data-name="Email" placeholder="Enter your email" type="email"
                                        id="Email" required=""/><input type="submit" data-wait=""
                                                                       className="button-subscribe w-button"
                                                                       value=""/></form>
                                    <div className="success-message w-form-done" tabIndex="-1" role="region"
                                         aria-label="Subscribe Form success">
                                        <div>Thank you! Your submission has been received!</div>
                                    </div>
                                    <div className="error-message w-form-fail" tabIndex="-1" role="region"
                                         aria-label="Subscribe Form failure">
                                        <div>Oops! Something went wrong while submitting the form.</div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h6 className="footer-social-title">Follow us on</h6>
                                <div className="footer-social-inline">
                                    <a href="https://www.facebook.com/" target="_blank"
                                       className="footer-social-link w-inline-block">
                                        <img
                                            src={facebookIcon}
                                            loading="eager" alt="Social Icon" className="footer-social-icon"/>
                                        <div><span>Facebook</span></div>
                                    </a>
                                    <a href="https://www.instagram.com/" target="_blank"
                                       className="footer-social-link w-inline-block">
                                        <img
                                            src={instagramIcon}
                                            loading="eager" alt="Social Icon" className="footer-social-icon"/>
                                        <div>Instagram</div>
                                    </a>
                                    <a href="https://twitter.com/" target="_blank"
                                       className="footer-social-link w-inline-block">
                                        <img
                                            src={twitterIcon}
                                            loading="eager" alt="Social Icon" className="footer-social-icon"/>
                                        <div>Twitter</div>
                                    </a>
                                    <a href="https://in.linkedin.com/" target="_blank"
                                       className="footer-social-link w-inline-block">
                                        <img
                                            src={linkedInIcon}
                                            loading="eager" alt="Social Icon" className="footer-social-icon"/>
                                        <div>LinkedIn</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div id="w-node-_9807520c-65b7-e828-71bd-909a6cfe1805-6cfe1801"
                             data-w-id="9807520c-65b7-e828-71bd-909a6cfe1805" className="footer-item"
                             style={{opacity: 1}}>
                            <h6 className="footer-title">BiteMate links</h6>
                            <div className="w-layout-grid grid-footer-menu">
                                <div className="footer-links">
                                    <a
                                        href={'/'} aria-current="page"
                                        className={`footer-link ${pathname === '/' ? "w--current" : ""}`}>Home</a>
                                    <a
                                        href={'/pricing'}
                                        className={`footer-link ${pathname === '/pricing' ? "w--current" : ""}`}>Pricing</a>
                                    <a
                                        href={'/request-demo'}
                                        className={`footer-link ${pathname === '/request-demo' ? "w--current" : ""}`}>Request A Demo</a>
                                    <a
                                        href={'https://app.bitemate.co'}
                                        className={`footer-link`}>Client Dashboard</a>
                                    <a
                                        href={'/privacy-policy'}
                                        className={`footer-link ${pathname === '/privacy-policy' ? "w--current" : ""}`}>Privacy Policy</a>
                                    <a
                                        href={'/terms-conditions'}
                                        className={`footer-link ${pathname === '/terms-conditions' ? "w--current" : ""}`}>Terms and Conditions</a>
                                </div>
                                {/*<div className="footer-links">*/}
                                {/*    <a*/}
                                {/*    href="https://saspro-wbs.webflow.io/product-pages/pricing-2-ecom"*/}
                                {/*    className="footer-link">Privacy Policy</a>*/}
                                {/*    <a*/}
                                {/*    href="https://saspro-wbs.webflow.io/product-pages/pricing-3"*/}
                                {/*    className="footer-link">Terms and Conditions</a>*/}
                                {/*    /!*<a*!/*/}
                                {/*    /!*href="https://saspro-wbs.webflow.io/company-pages/careers"*!/*/}
                                {/*    /!*className="footer-link">Careers</a>*!/*/}
                                {/*    /!*<a*!/*/}
                                {/*    /!*href="https://saspro-wbs.webflow.io/company-pages/about"*!/*/}
                                {/*    /!*className="footer-link">About</a>*!/*/}
                                {/*    /!*<a*!/*/}
                                {/*    /!*href="https://saspro-wbs.webflow.io/help-pages/faqs"*!/*/}
                                {/*    /!*className="footer-link">FAQs</a>*!/*/}
                                {/*    /!*<a*!/*/}
                                {/*    /!*href="https://saspro-wbs.webflow.io/company-pages/blog"*!/*/}
                                {/*    /!*className="footer-link">Blog</a>*!/*/}
                                {/*    /!*<a*!/*/}
                                {/*    /!*href="https://saspro-wbs.webflow.io/help-pages/contact"*!/*/}
                                {/*    /!*className="footer-link">Contact</a>*!/*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div id="w-node-_9807520c-65b7-e828-71bd-909a6cfe183b-6cfe1801" className="footer-item">
                            <div data-w-id="9807520c-65b7-e828-71bd-909a6cfe183c" style={{opacity: 1}}>
                                <h6 className="footer-title">Help</h6>
                                <div className="footer-links">
                                    <a
                                    href={'/help-center'}
                                    className="footer-link">Help Center</a>
                                    <a
                                    href={'/faq'}
                                    className="footer-link">FAQ</a>
                                    <a
                                    href={'/changelog'}
                                    className="footer-link">Changelog</a>
                                    <a
                                    href={'/request-demo'}
                                    className="footer-link">Contact Us</a>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div id="w-node-_4ab3af6a-9bb2-bc55-8f57-ef76ac04ad80-6cfe1801"
                         className="footer-divider"></div>
                </div>
                <p data-w-id="9807520c-65b7-e828-71bd-909a6cfe184a" className="footer-copyright"
                   style={{opacity: 1}}>©2024 BiteMate | Powered by <a
                    href="https://webflow.com/" target="_blank" className="copyright-link">Younivision</a></p>
                <a href="https://www.bitemate.co"
                   id="w-node-_9807520c-65b7-e828-71bd-909a6cfe182c-6cfe1801" aria-current="page"
                   className="footer-logo-wrap w-inline-block w--current">
                    {/*<img src={logoBig} loading="eager" alt="Logo" className="footer-logo"/>*/}
                </a>
            </div>
            <img src={unknownImage}
                 loading="eager" alt="Decoration" className="footer-decoration"/>
        </footer>
    )

}

export default Footer
