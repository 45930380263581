import decoration03 from "../../../../assets/images/defaults/6641b35fbdafbc2bc9b06b24_decoration-03-1.svg"
import vectorImage from "../../../../assets/images/defaults/6641b94df7dbcda617d071b4_Vector-1.svg"
import helpIcon from "../../../../assets/images/defaults/6645d6989bb63cb07a7d19da_help-02.svg"

const Hero = () => {

    return (
        <>
            <section className="split-pricing-section">
                <div className="help-center-categories-wrap">
                    <img src={decoration03} loading="eager" alt="Decoration"
                         className="pricing-decoration-three"/><img
                    src={vectorImage} loading="eager" alt="Decoration"
                    className="pricing-decoration-four"/>
                    <div className="w-layout-blockcontainer container-medium w-container">
                        <div className="help-center-categories-title">
                            <div className="help-center-category-icon-wrap"><img
                                src={helpIcon} loading="eager" width="26"
                                alt="" className="help-center-category-icon"/></div>
                            <h1 className="text-white">FAQs</h1>
                            <p className="text-white">The generated Lorem Ipsum is therefore always free from.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}

export default Hero;
