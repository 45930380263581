import TopicSelector from "./Components/TopicSelector";
import Hero from "./Components/Hero";
import PopularArticles from "./Components/PopularArticles";

const HelpCenterPage = () => {

    return (
        <>
            <Hero />
            <TopicSelector />
            <PopularArticles />
        </>
    )

}

export default HelpCenterPage
