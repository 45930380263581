import './App.css';
import './/styles/saspro-wbs.webflow.e933e4b69.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Homepage from "./pages/Homepage";
import PricingPage from "./pages/Pricing";
import RequestDemo from "./pages/RequestDemo";
import Features from "./pages/Features";
import LandingLayout from "./layout/LandingLayout";
import HelpCenterPage from "./pages/HelpCenter/HelpCenter/HelpCenterPage";
import HelpCenterTopicPage from "./pages/HelpCenter/HelpTopic/HelpCenterTopicPage";
import HelpCenterLayout from "./layout/HelpCenterLayout";
import HelpCenterArticlePage from "./pages/HelpCenter/HelpArticle/HelpCenterArticlePage";
import NotFoundPage from "./pages/Utility/NotFoundPage";
import PrivacyPolicyPage from "./pages/Legal/PrivacyPolicyPage";
import TermsServicePage from "./pages/Legal/TermsServicePage";

function App() {
  return (
      <Router>
          <div style={{ maxWidth: '100vw', overflowX: 'hidden', width: '100vw'}}>
              <Routes>
                  <Route path="/" element={<LandingLayout />}>
                      <Route index element={<Homepage />} />
                      <Route path="pricing" element={<PricingPage />} />
                      <Route path="request-demo" element={<RequestDemo />} />
                      <Route path="features" element={<Features />} />
                      <Route path="help-center" element={<HelpCenterPage />} />
                  </Route>
                  <Route path="/help-center/topic" element={<HelpCenterLayout />}>
                      <Route index element={<HelpCenterTopicPage />} />
                      <Route path=":id" element={<HelpCenterTopicPage />} />
                      <Route path=":id/:articleId" element={<HelpCenterArticlePage />} />
                  </Route>
                  <Route path="/privacy-policy" element={<HelpCenterLayout />}>
                      <Route index element={<PrivacyPolicyPage />} />
                  </Route>
                  <Route path="terms-conditions" element={<HelpCenterLayout />}>
                      <Route index element={<TermsServicePage />} />
                  </Route>
                  <Route path="*" element={<NotFoundPage />} /> {/* Fallback route for unmatched URLs */}
              </Routes>
          </div>
      </Router>
  );
}

export default App;
