const Form = () => {

    function handleSubmit() {

    }

    return (
        <div data-w-id="3ffa4884-3322-8753-9a91-f4de7f3ad11d"
             style={{
                 opacity: 1,
                 transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                 transformStyle: 'preserve-3d'
             }}
             className="request-demo-item">
            <h2 className="text-center heading-h5">Fill out the form to request a demo</h2>
            <div id="Request-Demo-Form"
                 className="request-demo-form w-node-_3ffa4884-3322-8753-9a91-f4de7f3ad120-c52b096e w-form">
                <form id="wf-form-Request-Demo-Form" name="wf-form-Request-Demo-Form"
                      data-name="Request Demo Form" method="get"
                      data-wf-page-id="6645a85d1fad961bc52b096e"
                      data-wf-element-id="3ffa4884-3322-8753-9a91-f4de7f3ad121"
                      aria-label="Request Demo Form">
                    <div className="input-group-wrap">
                        <input className="form-input w-input"
                               maxLength="256" name="Field"
                               data-name="Field" placeholder="First Name"
                               type="text" id="Field-3"/>
                        <input
                                className="form-input w-input"
                                maxLength="256" name="Field" data-name="Field"
                                placeholder="Last Name" type="text" id="Field-4"/>
                    </div>
                    <div className="input-group-wrap">
                        <input className="form-input w-input"
                               maxLength="256" name="Email"
                               data-name="Email" placeholder="Email"
                               type="email" id="Email-6"
                               required=""/>
                        <input
                            className="form-input w-input"
                            maxLength="256" name="Phone-No"
                            data-name="Phone No" placeholder="Phone No"
                            type="tel" id="Phone-No-3"/>
                    </div>
                    <div className="input-group">
                        <textarea id="Message-3" name="Message"
                                  maxLength="5000" data-name="Message"
                                  placeholder="Message"
                                  className="form-input form-textarea w-input">
                        </textarea>
                    </div>
                    <div style={{ cursor: 'pointer'}} onClick={() => handleSubmit()} data-wait="Please wait..."
                           className="button-primary-1 w-button">Submit</div>
                </form>
                <div className="success-message w-form-done" tabIndex="-1" role="region"
                     aria-label="Request Demo Form success">
                    <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="error-message w-form-fail" tabIndex="-1" role="region"
                     aria-label="Request Demo Form failure">
                    <div>Oops! Something went wrong while submitting the form.</div>
                </div>
            </div>
        </div>
    )

}

export default Form
