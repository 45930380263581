const NotFoundPage = () => {

    return (
        <div className="utility-page-wrap">
            <div className="utility-page-content">
                <h1 className="error-title">404</h1>
                <h2 className="heading-h3">Page Not Found</h2>
                <div className="error-description">The page you are looking for doesn't exist or has been moved</div>
                <a href={'/'} className="button-primary-1 w-button">Back to
                    home</a>
            </div>
        </div>
    )

}

export default NotFoundPage
