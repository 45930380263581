import {useParams} from "react-router-dom";

const DEFAULT_TOPICS = [
    {index: 0, title: 'Understanding pricing plans', content: 'Unravel the intricacies of our pricing options and select the ideal plan tailored to meet your needs.', slug: 'pricing-plans', topic: 'pricing'},
    {index: 1, title: 'Common error messages', content: 'Decode and troubleshoot common issues encountered on our platform for a seamless user experience.', slug: 'error-messages', topic: 'handling'},
    {index: 2, title: 'Changing a password', content: 'Follow simple steps to change your password and safeguard your account with ease.', slug: 'change-password', topic: 'authentication'},
    {index: 3, title: 'Logging in', content: 'Unravel the intricacies of our pricing options and select the ideal plan tailored to meet your needs.', slug: 'logging-in', topic: 'authentication'}
]

const Articles = () => {

    const params = useParams();

    return (
        <section className="help-topic-section section-spacing-sm-top">
            <div className="w-layout-blockcontainer container-medium w-container">
                {/*<div data-w-id="5c0b3a1f-8355-b7d9-ea50-c6b0d7fb1772" style={{opacity: 1}}*/}
                {/*     className="section-title text-center">*/}
                {/*    <h2>Popular Articles</h2>*/}
                {/*</div>*/}
                <div data-w-id="aba44b8d-2976-5e79-dbfe-9587e85c90c8" style={{opacity: 1}} className="w-dyn-list">
                    <div role="list" className="grid-help-topic w-dyn-items">
                        {DEFAULT_TOPICS.map((item, index) => (
                            <div role="listitem" className="w-dyn-item">
                                <a aria-label="link"
                                   href={`/help-center/topic/${params?.id}/${item?.slug}`}
                                   className="help-topic-item w-inline-block">
                                    <h3 className="help-topic-title">{item?.title}</h3>
                                    <p className="no-margin">{item?.content}</p>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Articles
