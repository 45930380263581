import icon01 from "../../../../assets/images/defaults/6645d6989bb63cb07a7d19da_help-02.svg"
import icon02 from "../../../../assets/images/defaults/6645d6acf0f74f6e2b371b19_help-03.svg"
import icon03 from "../../../../assets/images/defaults/6645d6ba5eaf31578298056d_help-04.svg"
import icon04 from "../../../../assets/images/defaults/6645d6c7e2e119a7783022a7_help-06.svg"
import icon05 from "../../../../assets/images/defaults/6645d6d4fa782d7a42813294_help-05.svg"
import icon06 from "../../../../assets/images/defaults/6645d6dca67b104f57fdb542_help-01.svg"
import {useLocation} from "react-router-dom";

const DEFAULT_TOPICS = [
    {title: 'FAQs', content: 'The generated Lorem Ipsum is therefore always free from.', icon: icon01, slug: 'faq'},
    {title: 'Billing and Payments', content: 'To generate Lorem Ipsum which looks reasonable.', icon: icon02, slug: 'billing'},
    {title: 'Integrations', content: 'There are many variations of passages of Lorem Ipsum available.', icon: icon03, slug: 'integrations'},
    {title: 'Troubleshooting', content: 'Ipsum generators on the Internet tend to repeat predefined chunks.', icon: icon04, slug: 'troubleshooting'},
    {title: 'Account Settings', content: 'Combined with a handful of model sentence structures.', icon: icon05, slug: 'account-settings'},
    {title: 'Getting Started', content: 'Even slightly believable. If you are going to use a passage.', icon: icon06, slug: 'getting-started'},
]

const TopicSelector = () => {

    const router = useLocation();

    return (
        <section className="help-center-section section-spacing-bottom">
            <div className="w-layout-blockcontainer container w-container">
                <div data-w-id="404c4daf-7879-02ea-3e9c-849bd6bcb18c"
                     style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}
                     className="w-dyn-list">
                    <div role="list" className="grid-help-center-category w-dyn-items">
                        {DEFAULT_TOPICS.map((topic, index) => (
                            <div key={index} role="listitem" className="w-dyn-item">
                                <a aria-label="link" href={`/help-center/topic/${topic.slug}`}
                                   className="help-category-item w-inline-block">
                                    <div className="help-category-icon-wrap"><img
                                        src={topic?.icon}
                                        loading="eager" width="26" alt="" className="help-category-icon"/></div>
                                    <h2 className="heading-h5">{topic?.title}</h2>
                                    <p>{topic?.content}</p>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TopicSelector
