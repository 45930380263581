import decoration01 from "../../../assets/images/defaults/6641b344baa1ededaa5b27dd_decoration-01-1.svg";
import decoration03 from "../../../assets/images/defaults/6641b35fbdafbc2bc9b06b24_decoration-03-1.svg";
import Form from "./Form";

const Hero = () => {

    return (
        <div className="request-demo-wrap">
            <img src={decoration01} loading="eager" alt="Decoration" className="feature-decoration-one"/>
            <img src={decoration03} loading="eager" alt="Decoration" className="feature-decoration-two"/>
            <div className="w-layout-blockcontainer container-small w-container">
                <div data-w-id="398521a5-7f17-34ef-c10c-6efd49997331"
                     style={{
                         opacity: 1,
                         transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                         transformStyle: 'preserve-3d'
                     }}
                     className="section-title text-center">
                    <h1 className="text-white">Request a Demo</h1>
                    <p className="request-demo-description">Schedule a personalized demo to experience our
                        solution firsthand and discover how it can revolutionize your business operations.</p>
                </div>
                <Form />
            </div>
        </div>
    )
}

export default Hero;
