import mainLogoDark from "../assets/images/bitemate/DEFAULT_LOGO_DARK.svg";
import {useLocation} from "react-router-dom";

const AlternateNavBar = () => {

    const { pathname } = useLocation();

    return (
        <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease"
             data-easing2="ease" role="banner" className="navbar w-nav">
            <div className="container w-container">
                <div className="w-layout-grid grid-nav">
                    <a href={'/'}
                       id="w-node-e6ff9f79-f479-fa42-6f69-a3df18a8ef3f-18a8ef3c" className="brand w-nav-brand"><img
                        src={mainLogoDark} loading="lazy" alt="Logo" style={{maxHeight: '30px'}}
                        className="logo"/></a>
                    <nav role="navigation" id="w-node-e6ff9f79-f479-fa42-6f69-a3df18a8ef41-18a8ef3c"
                         className="nav-menu w-nav-menu">
                        <a href={'/'} className={`nav-link w-nav-link ${pathname === '/' || pathname === '' ? 'w--current' : ''}`}
                           style={{maxWidth: '1460px'}}>
                            Home</a>
                        <a href={'/features'} className={`nav-link w-nav-link ${pathname === '/features' ? 'w--current' : ''}`}
                           style={{maxWidth: '1460px'}}>
                            Features</a>
                        <a href={'/pricing'}
                           className={`nav-link w-nav-link ${pathname === '/pricing' ? 'w--current' : ''}`} style={{maxWidth: '1460px'}}>
                            Pricing</a>
                        <a
                        href={'/request-demo'} className={`nav-link w-nav-link ${pathname === 'request-demo' ? 'w--current' : ''}`}
                        style={{maxWidth: '1460px'}}>Request Demo</a>
                        <a
                        href="https://saspro-wbs.webflow.io/company-pages/blog" className={`nav-link w-nav-link ${pathname?.includes('help-center') ? 'w--current' : ''}`}
                        style={{maxWidth: '1460px'}}>Help Center</a>
                    </nav>
                    <div id="w-node-e6ff9f79-f479-fa42-6f69-a3df18a8ef64-18a8ef3c" className="right-nav">
                        <a href="https://app.bitemate.co/auth/sign-in"
                           className="button-link mobile-hide">Sign in</a><a
                        href="https://app.bitemate.co/auth/sign-up"
                        className="button-primary-1 mobile-hide w-button">Get started</a>
                        <div className="menu-button w-nav-button" style={{userSelect: 'text' }}
                             aria-label="menu" role="button" tabIndex="0" aria-controls="w-nav-overlay-0"
                             aria-haspopup="menu" aria-expanded="false">
                            <div className="w-icon-nav-menu"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"></div>
        </div>
    )

}

export default AlternateNavBar
