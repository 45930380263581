
const Faq = () => {

    return (
        <section className="split-faqs-section section-spacing-top">
            <div className="w-layout-blockcontainer container w-container">
                <div data-w-id="360cbedd-52f0-da3c-37a3-460afc0b6b38"
                     style={{
                         opacity: 1,
                         transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                         transformStyle: 'preserve-3d'
                     }}
                     className="w-layout-grid grid-split-faqs">
                    <h2>Frequently asked<br/>questions</h2>
                    <div id="w-node-_339fb941-6f9d-eb0a-b0c9-bafa3a90d382-29356f8a">
                        <div>
                            <div style={{backgroundColor: 'rgb(255, 255, 255)'}} className="accordion-wrap">
                                <div data-w-id="73443827-2def-5255-d692-752ba4ae9ffa" className="accordion-heading">
                                    <h3 className="accordion-title">Can I integrate your product with other
                                        tools?</h3>
                                    <div className="accordion-icon-wrap">
                                        <div className="accordion-icon-plus"></div>
                                        <div
                                            style={{
                                                transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(90deg) skew(0deg, 0deg)',
                                                transformStyle: 'preserve-3d'
                                            }}
                                            className="accordion-icon-minus"></div>
                                    </div>
                                </div>
                                <div style={{height: '0px'}} className="accordion-content-wrap">
                                    <p className="accordion-content">Many SaaS solutions offer integration
                                        capabilities, allowing users to connect with various applications and
                                        services to streamline workflows and enhance productivity. Check our
                                        integration documentation or contact support for specific details on
                                        compatibility.</p>
                                </div>
                                <div style={{opacity: 0.1}} className="faqs-divider"></div>
                            </div>
                            <div style={{backgroundColor: 'rgb(255, 255, 255)'}} className="accordion-wrap">
                                <div data-w-id="73443827-2def-5255-d692-752ba4aea005" className="accordion-heading">
                                    <h4 className="accordion-title">Is there customer support available?</h4>
                                    <div className="accordion-icon-wrap">
                                        <div className="accordion-icon-plus"></div>
                                        <div
                                            style={{
                                                transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(90deg) skew(0deg, 0deg)',
                                                transformStyle: 'preserve-3d'
                                            }}
                                            className="accordion-icon-minus"></div>
                                    </div>
                                </div>
                                <div style={{height: '0px'}} className="accordion-content-wrap">
                                    <p className="accordion-content">Yes, most SaaS providers offer customer support
                                        via multiple channels, such as email, live chat, or phone, to assist users
                                        with queries, technical issues, or guidance on using the software
                                        effectively.</p>
                                </div>
                                <div style={{opacity: 0.1}} className="faqs-divider"></div>
                            </div>
                            <div style={{backgroundColor: 'rgb(255, 255, 255)'}} className="accordion-wrap">
                                <div data-w-id="73443827-2def-5255-d692-752ba4aea010" className="accordion-heading">
                                    <h5 className="accordion-title">Is there a free trial available?</h5>
                                    <div className="accordion-icon-wrap">
                                        <div className="accordion-icon-plus"></div>
                                        <div
                                            style={{
                                                transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(90deg) skew(0deg, 0deg)',
                                                transformStyle: 'preserve-3d'
                                            }}
                                            className="accordion-icon-minus"></div>
                                    </div>
                                </div>
                                <div style={{height: '0px'}} className="accordion-content-wrap">
                                    <p className="accordion-content">Yes, we offer a 3 month free trial period for
                                        users to experience our product/service. Sign up on our website to get
                                        started.</p>
                                </div>
                                <div style={{opacity: 0.1}} className="faqs-divider"></div>
                            </div>
                            <div style={{backgroundColor: 'rgb(255, 255, 255)'}} className="accordion-wrap">
                                <div data-w-id="73443827-2def-5255-d692-752ba4aea01b" className="accordion-heading">
                                    <h6 className="accordion-title">How can I upgrade or downgrade my subscription
                                        plan?</h6>
                                    <div className="accordion-icon-wrap">
                                        <div className="accordion-icon-plus"></div>
                                        <div
                                            style={{
                                                transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(90deg) skew(0deg, 0deg)',
                                                transformStyle: 'preserve-3d'
                                            }}
                                            className="accordion-icon-minus"></div>
                                    </div>
                                </div>
                                <div style={{height: '0px'}} className="accordion-content-wrap">
                                    <p className="accordion-content">You can easily upgrade or downgrade your
                                        subscription plan through your account settings. If you need assistance,
                                        contact our support team for guidance.</p>
                                </div>
                                <div style={{opacity: 0.1}} className="faqs-divider"></div>
                            </div>
                            <div style={{backgroundColor: 'rgb(255, 255, 255)'}} className="accordion-wrap">
                                <div data-w-id="73443827-2def-5255-d692-752ba4aea026" className="accordion-heading">
                                    <h6 className="accordion-title">How can I protect my software from viruses and
                                        malware?</h6>
                                    <div className="accordion-icon-wrap">
                                        <div className="accordion-icon-plus"></div>
                                        <div
                                            style={{
                                                transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(90deg) skew(0deg, 0deg)',
                                                transformStyle: 'preserve-3d'
                                            }}
                                            className="accordion-icon-minus"></div>
                                    </div>
                                </div>
                                <div style={{height: '0px'}} className="accordion-content-wrap">
                                    <p className="accordion-content">To protect your software from viruses and
                                        malware, install reputable antivirus software, keep your operating system
                                        and applications up to date, avoid downloading software from untrusted
                                        sources, and regularly scan your system for threats.</p>
                                </div>
                                <div style={{opacity: 0.1}} className="faqs-divider"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Faq
