import client01 from "../../../assets/images/defaults/6638c3844b10c2074e927d77_client-01.svg";
import client05 from "../../../assets/images/defaults/6638c3848adcfd1d18bdff53_client-05.svg";
import client03 from "../../../assets/images/defaults/6638c38497b819e318406f2d_client-03.svg";
import client08 from "../../../assets/images/defaults/6638c3841ca889d02feca5e0_client-08.svg";
import client06 from "../../../assets/images/defaults/6638c384d9411536ccc3b42d_client-06.svg";
import client09 from "../../../assets/images/defaults/6638c385e2f89ed5a78fc70c_client-09.svg";
import client10 from "../../../assets/images/defaults/6638c385ffbccf052fd7c988_client-10.svg";
import client02 from "../../../assets/images/defaults/6638c387474aecb7a34cd1c9_client-02.svg";
import client04 from "../../../assets/images/defaults/6638c387e4b1e6b3d893ab01_client-04.svg";

const Integrations = () => {

    return (
        <div className="w-layout-blockcontainer container w-container">
            <div data-w-id="e8aac31a-8e7e-80b3-cba5-c23acacc1bd6"
                 style={{
                     opacity: 1,
                     transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                     transformStyle: 'preserve-3d'
                 }}
                 className="client-wrapper overflow-hidden">
                <div className="w-layout-grid grid-client">
                    <h5 className="client-section-title">Easily integrate with <span
                        className="text-primary-1">TOP</span>  POS system providers</h5>
                    <div id="w-node-e8aac31a-8e7e-80b3-cba5-c23acacc1bdd-c52b096e"
                         data-w-id="e8aac31a-8e7e-80b3-cba5-c23acacc1bdd" className="client-marquee">
                        <div
                            style={{
                                transform: 'translate3d(-624.955px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                                transformStyle: 'preserve-3d',
                                willChange: 'transform'
                            }}
                            className="client-marquee-list"><img
                            src={client01}
                            loading="eager" alt="Client Image" className="client-image"/><img
                            src={client05}
                            loading="eager" alt="Client Image" className="client-image"/><img
                            src={client03}
                            loading="eager" alt="Client Image" className="client-image"/><img
                            src={client08}
                            loading="eager" alt="Client Image" className="client-image"/><img
                            src={client06}
                            loading="eager" alt="Client Image" className="client-image"/><img
                            src={client09}
                            loading="eager" alt="Client Image" className="client-image"/><img
                            src={client10}
                            loading="eager" alt="Client Image" className="client-image"/><img
                            src={client02}
                            loading="eager" alt="Client Image" className="client-image"/><img
                            src={client04}
                            loading="eager" alt="Client Image" className="client-image"/></div>
                        <div
                            style={{
                                transform: 'translate3d(-624.955px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d',
                                willChange: 'transform'
                            }}
                            className="client-marquee-list"><img
                            src={client01}
                            loading="eager" alt="Client Image" className="client-image"/><img
                            src={client05}
                            loading="eager" alt="Client Image" className="client-image"/><img
                            src={client03}
                            loading="eager" alt="Client Image" className="client-image"/><img
                            src={client08}
                            loading="eager" alt="Client Image" className="client-image"/><img
                            src={client06}
                            loading="eager" alt="Client Image" className="client-image"/><img
                            src={client09}
                            loading="eager" alt="Client Image" className="client-image"/><img
                            src={client10}
                            loading="eager" alt="Client Image" className="client-image"/><img
                            src={client02}
                            loading="eager" alt="Client Image" className="client-image"/><img
                            src={client04}
                            loading="eager" alt="Client Image" className="client-image"/></div>
                        <div
                            style={{
                                transform: 'translate3d(-624.955px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                                transformStyle: 'preserve-3d',
                                willChange: 'transform'
                            }}
                            className="client-marquee-list"><img
                            src={client01}
                            loading="eager" alt="Client Image" className="client-image"/><img
                            src={client05}
                            loading="eager" alt="Client Image" className="client-image"/><img
                            src={client03}
                            loading="eager" alt="Client Image" className="client-image"/><img
                            src={client08}
                            loading="eager" alt="Client Image" className="client-image"/><img
                            src={client06}
                            loading="eager" alt="Client Image" className="client-image"/><img
                            src={client09}
                            loading="eager" alt="Client Image" className="client-image"/><img
                            src={client10}
                            loading="eager" alt="Client Image" className="client-image"/><img
                            src={client02}
                            loading="eager" alt="Client Image" className="client-image"/><img
                            src={client04}
                            loading="eager" alt="Client Image" className="client-image"/></div>
                    </div>
                </div>
                <div className="client-decoration-two"></div>
                <div className="client-decoration-three"></div>
                <img
                    src="./Request a Demo _ SasPro - Webflow Ecommerce website template_files/6641b94df7dbcda617d071b4_Vector.svg"
                    loading="eager" alt="Decoration" className="client-decoration-one"/>
            </div>
        </div>
    )
}

export default Integrations;
