import decoration06 from "../../../assets/images/defaults/6638bd8b739f208d67f39661_decoration-06.svg";
import featureIcon01 from "../../../assets/images/defaults/6638bfdbd62c09a42b929503_feature-icon-01.svg";
import featureIcon02 from "../../../assets/images/defaults/663b27d4f733ff4b8449fb4b_feature-icon-02.svg";
import featureIcon03 from "../../../assets/images/defaults/663b27e3938baeb7955afda3_feature-icon-03.svg";
import client01 from "../../../assets/images/defaults/6638c3844b10c2074e927d77_client-01.svg";
import client05 from "../../../assets/images/defaults/6638c3848adcfd1d18bdff53_client-05.svg";
import client03 from "../../../assets/images/defaults/6638c38497b819e318406f2d_client-03.svg";
import client08 from "../../../assets/images/defaults/6638c3841ca889d02feca5e0_client-08.svg";
import client06 from "../../../assets/images/defaults/6638c384d9411536ccc3b42d_client-06.svg";
import client09 from "../../../assets/images/defaults/6638c385e2f89ed5a78fc70c_client-09.svg";
import client10 from "../../../assets/images/defaults/6638c385ffbccf052fd7c988_client-10.svg";
import client02 from "../../../assets/images/defaults/6638c387474aecb7a34cd1c9_client-02.svg";
import client04 from "../../../assets/images/defaults/6638c387e4b1e6b3d893ab01_client-04.svg";
import imageVector from "../../../assets/images/defaults/6641b94df7dbcda617d071b4_Vector-1.svg";

const DEFAULT_FEATURES = [
    {index: 0, bg: 'bg-secondary-2', title: 'Real-Time Menu & Item Updates', content: 'Manage and modify your menu, add items, or  adjust pricing instantly from the admin  dashboard.', icon: featureIcon01 },
    {index: 1, bg: 'bg-secondary-1', title: 'Multiple Locations, One App', content: 'Operate  multiple restaurant locations with ease,  managing them all from one centralized  platform.', icon: featureIcon02 },
    {index: 2, bg: 'bg-primary-3', title: 'Push Notifications & Promotions', content: 'Rest assured knowing that your data is safe and secure with our robust security measures.', icon: featureIcon03 },
    {index: 3, bg: 'bg-secondary-2', title: 'Customizable Rewards Program', content: 'Create  and manage a rewards program that keeps  customers coming back. Fully customizable to  suit your brand.', icon: featureIcon01 },
    {index: 4, bg: 'bg-secondary-1', title: 'Instant Theme Changes', content: 'Update the look  and feel of your app with real-time theme  adjustments from the admin dashboard.  Reflect seasonal themes or update branding  instantly.', icon: featureIcon02 },
    {index: 5, bg: 'bg-primary-3', title: '30-Day Free Trial', content: 'Test-drive the app  with no commitment. Experience firsthand  how our platform can simplify operations and  drive customer loyalty.', icon: featureIcon03 },
]

const Features = () => {

    return (
        <section className="feature-section">
            <div className="w-layout-blockcontainer container w-container">
                {/* <div data-w-id="8dac8be9-7034-9a51-4ac5-1f6b29c8a746"
                     style={{
                         opacity: 1,
                         transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                         transformStyle: 'preserve-3d'
                     }}
                     className="feature-content-wrap">
                    <img
                        src={decoration06}
                        loading="eager" alt="Decoration" className="decoration-six"/>
                    <h2 className="feature-section-title">
                        Key Features Built for Restaurant Success
                    </h2>
                    <p className="feature-content">With a team of industry experts and cutting-edge technology, we
                        deliver comprehensive SaaS solutions tailored to meet the unique needs of each client. From
                        workflow optimization to customer relationship management, our platforms are designed to
                        unlock new opportunities and propel businesses forward.</p>
                </div> */}
                {/* <div style={{textAlign: 'left'}} className="w-layout-grid grid-feature-item">
                    {DEFAULT_FEATURES.map((feature, index) => (
                        <div key={index} id="w-node-_8dac8be9-7034-9a51-4ac5-1f6b29c8a74d-1715374c"
                             data-w-id="8dac8be9-7034-9a51-4ac5-1f6b29c8a74d"
                             style={{
                                 opacity: 1,
                                 transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                                 transformStyle: 'preserve-3d'
                             }}
                             className="feature-item">
                            <div className={`feature-icon-wrap ${feature?.bg}`}><img
                                src={feature?.icon}
                                loading="lazy" alt="Feature Icon" className="feature-icon"/></div>
                            <div>
                                <h3 className="feature-title">{feature?.title}</h3>
                                <p className="no-margin">{feature?.content}</p>
                            </div>
                        </div>
                    ))}
                </div> */}
                <div data-w-id="8dac8be9-7034-9a51-4ac5-1f6b29c8a765"
                     style={{
                         opacity: 1,
                         textAlign: 'left',
                         transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                         transformStyle: 'preserve-3d'
                     }}
                     className="client-wrap overflow-hidden">
                    <div className="w-layout-grid grid-client">
                        <h5 className="client-section-title">Easily integrate with <span
                            className="text-primary-1">TOP </span> POS system providers</h5>
                        <div id="w-node-_8dac8be9-7034-9a51-4ac5-1f6b29c8a76c-1715374c"
                             data-w-id="8dac8be9-7034-9a51-4ac5-1f6b29c8a76c" className="client-marquee">
                            <div
                                style={{
                                    transform: 'translate3d(-624.955px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                                    transformStyle: 'preserve-3d',
                                    willChange: 'transform'
                                }}
                                className="client-marquee-list"><img
                                src={client01}
                                loading="eager" alt="Client Image" className="client-image"/><img
                                src={client05}
                                loading="eager" alt="Client Image" className="client-image"/><img
                                src={client03}
                                loading="eager" alt="Client Image" className="client-image"/><img
                                src={client08}
                                loading="eager" alt="Client Image" className="client-image"/><img
                                src={client06}
                                loading="eager" alt="Client Image" className="client-image"/><img
                                src={client09}
                                loading="eager" alt="Client Image" className="client-image"/><img
                                src={client10}
                                loading="eager" alt="Client Image" className="client-image"/><img
                                src={client02}
                                loading="eager" alt="Client Image" className="client-image"/><img
                                src={client04}
                                loading="eager" alt="Client Image" className="client-image"/></div>
                            <div
                                style={{
                                    transform: 'translate3d(-624.955px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d',
                                    willChange: 'transform'
                                }}
                                className="client-marquee-list"><img
                                src={client01}
                                loading="eager" alt="Client Image" className="client-image"/><img
                                src={client05}
                                loading="eager" alt="Client Image" className="client-image"/><img
                                src={client03}
                                loading="eager" alt="Client Image" className="client-image"/><img
                                src={client08}
                                loading="eager" alt="Client Image" className="client-image"/><img
                                src={client06}
                                loading="eager" alt="Client Image" className="client-image"/><img
                                src={client09}
                                loading="eager" alt="Client Image" className="client-image"/><img
                                src={client10}
                                loading="eager" alt="Client Image" className="client-image"/><img
                                src={client02}
                                loading="eager" alt="Client Image" className="client-image"/><img
                                src={client04}
                                loading="eager" alt="Client Image" className="client-image"/></div>
                            <div
                                style={{
                                    transform: 'translate3d(-624.955px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                                    transformStyle: 'preserve-3d',
                                    willChange: 'transform'
                                }}
                                className="client-marquee-list"><img
                                src={client01}
                                loading="eager" alt="Client Image" className="client-image"/><img
                                src={client05}
                                loading="eager" alt="Client Image" className="client-image"/><img
                                src={client03}
                                loading="eager" alt="Client Image" className="client-image"/><img
                                src={client08}
                                loading="eager" alt="Client Image" className="client-image"/><img
                                src={client06}
                                loading="eager" alt="Client Image" className="client-image"/><img
                                src={client09}
                                loading="eager" alt="Client Image" className="client-image"/><img
                                src={client10}
                                loading="eager" alt="Client Image" className="client-image"/><img
                                src={client02}
                                loading="eager" alt="Client Image" className="client-image"/><img
                                src={client04}
                                loading="eager" alt="Client Image" className="client-image"/></div>
                        </div>
                    </div>
                    <div className="client-decoration-two"></div>
                    <div className="client-decoration-three"></div>
                    <img
                        src={imageVector}
                        loading="eager" alt="Decoration" className="client-decoration-one"/>
                </div>
            </div>
        </section>
    )
}

export default Features;
